import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Backdrop,
  CircularProgress,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Snackbar,
  Alert,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
  TableSortLabel,
  InputAdornment,
  Paper as MuiPaper,
  Tooltip,
  IconButton,
  Tabs,
  Tab,
  Typography,
  StepContent,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import {
  Add as AddIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Photo as PhotoIcon,
  AccessTime as AccessTimeIcon,
  Build as BuildIcon,
  HourglassEmpty as HourglassEmptyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  PauseCircleOutline as PauseCircleOutlineIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Stepper, Step, StepLabel } from '@mui/material';
import { MenuItem } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { storage } from '../firebase';
import { ref, uploadBytes } from 'firebase/storage';


const Bau = () => {
  // Состояния
  const [bauData, setBauData] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [dense, setDense] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchInputRef, setSearchInputRef] = useState(null);
  const searchContainerRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newBau, setNewBau] = useState({
    kostenstelle_vvo: '',
    bauvorhaben: '',
    ort: '',
    strasse: '',
    ausfurung_von: null,
    ausfurung_bis: null,
    ansprechpartner: '',
    umbau_datum: null,
    status: 'Aktiv',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isLoading, setIsLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Основная информация', 'Адрес', 'Даты', 'Папка'];

  const [selectedFolder, setSelectedFolder] = useState('');

  // Состояние для вкладок
  const [currentTab, setCurrentTab] = useState('Aktiv');

  // Заголовки таблицы
  const headCells = [
    { id: 'id', label: 'ID', numeric: true, minWidth: 30, width: 70 },
    { id: 'kostenstelle_vvo', label: 'Kostenstelle VVO', numeric: false, minWidth: 150, width: 180 },
    { id: 'bauvorhaben', label: 'Bauvorhaben', numeric: false, minWidth: 200, width: 300 },
    { id: 'ansprechpartner', label: 'Ansprechpartner', numeric: false, minWidth: 150 },
    { id: 'ort', label: 'Ort', numeric: false, minWidth: 100 },
    { id: 'strasse', label: 'Straße', numeric: false, minWidth: 150 },
    { id: 'hverbor_datum', label: 'H.verbot Datum', numeric: false, minWidth: 120 },
    { id: 'ausfurung_von', label: 'Ausführung Von', numeric: false, minWidth: 120 },
    { id: 'ausfurung_bis', label: 'Ausführung Bis', numeric: false, minWidth: 120 },
    { id: 'actions', label: '', numeric: false, minWidth: 150 },
  ];

  // Тема
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
      background: {
        default: '#121212',
        paper: '#1d1d1d',
      },
      text: {
        primary: '#ffffff',
        secondary: '#b0b0b0',
      },
    },
    typography: {
      fontSize: 14,
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            tableLayout: 'fixed',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
          head: {
            backgroundColor: 'grey',
            color: 'black !important',
            fontWeight: 'bold',
            padding: '8px 6px',
            textAlign: 'center',
          },
        },
      },
    },
  });

  // URL вашего API
  const API_URL = 'https://us-central1-vigilant-mix-403621.cloudfunctions.net/api';

  // Получение данных с бэкенда
  const fetchBauData = async () => {
    try {
      const response = await axios.get(`${API_URL}/projects`);
      setBauData(response.data);
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      setSnackbarMessage('Данные не удалось загрузить.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    fetchBauData();
  }, []);

  // Обработчики сортировки
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Обработчик изменения вкладки
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setPage(0); // Сбрасываем страницу пагинации при смене вкладки
  };

  // Функция для вычисления приоритета
  const calculatePriority = (row) => {
    const today = moment().startOf('day');
    const ausfurungVon = row.ausfurung_von ? moment(row.ausfurung_von, 'DD.MM.YYYY') : null;
    const ausfurungBis = row.ausfurung_bis ? moment(row.ausfurung_bis, 'DD.MM.YYYY') : null;

    if (!ausfurungVon) return 999; // Если дата не указана, ставим низкий приоритет

    const daysUntilAusfurungVon = ausfurungVon.diff(today, 'days');
    const daysUntilAusfurungBis = ausfurungBis ? ausfurungBis.diff(today, 'days') : null;

    const weekDifference = ausfurungVon.isoWeek() - today.isoWeek();

    // Проверяем условия
    if (ausfurungVon.isSame(today, 'day')) {
      return 1; // Сегодня
    } else if (ausfurungVon.isAfter(today, 'day') && weekDifference === 0) {
      return 1; // На этой неделе
    } else if (weekDifference === 1 || (today.isoWeekday() === 7 && ausfurungVon.isoWeekday() === 1 && weekDifference === 0)) {
      return 2; // Следующая неделя
    } else if (weekDifference >= 2) {
      return 3; // Через две недели или позже
    } else if (ausfurungVon.isBefore(today, 'day')) {
      // ausfurung_von в прошлом
      if (daysUntilAusfurungBis !== null && daysUntilAusfurungBis >= 0 && daysUntilAusfurungBis <= 7) {
        return 4; // До ausfurung_bis осталось меньше 7 дней
      }
      return 5; // Остальные в прошлом
    } else {
      return 6; // Все остальные
    }
  };


  // Сортировка
  const sortedData = React.useMemo(() => {
    let dataToSort = [...bauData];

    // Фильтрация данных
    dataToSort = dataToSort.filter((item) => {
      // Фильтрация по статусу
      const statusMatch = item.status === currentTab;

      // Фильтрация по поисковому запросу
      const searchMatch =
        !searchQuery ||
        ['bauvorhaben', 'ort', 'strasse', 'ansprechpartner'].some((field) =>
          item[field]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

      return statusMatch && searchMatch;
    });

    if (currentTab === 'Aktiv') {
      // Кастомная сортировка для вкладки "Aktiv"
      dataToSort.sort((a, b) => {
        const priorityA = calculatePriority(a);
        const priorityB = calculatePriority(b);

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        } else {
          // Если приоритеты равны
          if (priorityA === 5) {
            // Для зелёных проектов сортируем по ausfurung_bis
            const ausfurungBisA = a.ausfurung_bis ? moment(a.ausfurung_bis, 'DD.MM.YYYY') : moment.invalid();
            const ausfurungBisB = b.ausfurung_bis ? moment(b.ausfurung_bis, 'DD.MM.YYYY') : moment.invalid();

            if (!ausfurungBisA.isValid() && !ausfurungBisB.isValid()) return 0;
            if (!ausfurungBisA.isValid()) return 1;
            if (!ausfurungBisB.isValid()) return -1;

            return ausfurungBisA.diff(ausfurungBisB);
          } else {
            // Иначе сортируем по ausfurung_von
            const dateA = a.ausfurung_von ? moment(a.ausfurung_von, 'DD.MM.YYYY') : moment.invalid();
            const dateB = b.ausfurung_von ? moment(b.ausfurung_von, 'DD.MM.YYYY') : moment.invalid();

            if (!dateA.isValid() && !dateB.isValid()) return 0;
            if (!dateA.isValid()) return 1;
            if (!dateB.isValid()) return -1;

            return dateA.diff(dateB);
          }
        }
      });
    } else {
      // Обычная сортировка
      dataToSort.sort(getComparator(order, orderBy));
    }

    return dataToSort;
  }, [bauData, order, orderBy, currentTab, searchQuery]);

  // Пагинация для отфильтрованных данных
  const visibleRows = React.useMemo(
    () =>
      sortedData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [sortedData, page, rowsPerPage]
  );

  // Пагинация
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Обработчики SpeedDial
  const handleSpeedDialOpen = () => setSpeedDialOpen(true);
  const handleSpeedDialClose = () => setSpeedDialOpen(false);

  // Обработчик строки поиска
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0);
  };

  // Обработчик кликов вне области поиска
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        if (!searchQuery) {
          setIsSearchOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchQuery]);

  // Открытие диалога создания новой стройки
  const handleDialogOpen = () => {
    setDialogOpen(true);
    setNewBau({
      kostenstelle_vvo: '',
      bauvorhaben: '',
      ort: '',
      strasse: '',
      ausfurung_von: null,
      ausfurung_bis: null,
      ansprechpartner: '',
      umbau_datum: null,
      status: 'Aktiv',
    });
  };

  const handleEditConstruction = (row) => {
    // Логика редактирования стройки
  };

  const handleViewPhotos = (row) => {
    // Логика просмотра фотографий
  };

  const handleViewHours = (row) => {
    // Логика просмотра или добавления часов
  };

  const handleManageMaterials = (row) => {
    // Логика управления материалами
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Обработчик изменения полей ввода
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBau((prev) => ({ ...prev, [name]: value }));
  };

  // Сохранение новой стройки
  const handleSaveBau = async () => {
    setIsLoading(true);
    try {
      // Подготовка данных для сохранения
      const bauDataToSave = {
        ...newBau,
        ausfurung_von: newBau.ausfurung_von
          ? moment(newBau.ausfurung_von).format('DD.MM.YYYY')
          : null,
        ausfurung_bis: newBau.ausfurung_bis
          ? moment(newBau.ausfurung_bis).format('DD.MM.YYYY')
          : null,
        umbau_datum: newBau.umbau_datum
          ? moment(newBau.umbau_datum).format('DD.MM.YYYY')
          : null,
      };

      // Отправка данных на сервер
      const response = await axios.post(`${API_URL}/projects`, bauDataToSave);

      if (response.status === 201) {
        setSnackbarMessage('Стройка успешно создана!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleDialogClose();
        fetchBauData();

        // Создание папок в Firebase Storage
        await createFirebaseFolders(newBau.kostenstelle_vvo);
      } else {
        throw new Error('Ошибка при создании стройки');
      }
    } catch (error) {
      console.error('Ошибка при создании стройки:', error);
      setSnackbarMessage('Не удалось создать стройку. Попробуйте еще раз.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };


  //Создание папок в firebase storage
  const createFirebaseFolders = async (kostenstelle_vvo) => {
    try {
      const baseFolder = `Verkehrssicherung/${kostenstelle_vvo}/`;
      const folders = ['Photo/', 'VZP/', 'VRAO/'];

      for (const folder of folders) {
        const storageRef = ref(storage, baseFolder + folder + 'placeholder.txt');
        const placeholderData = new Blob(['This is a placeholder file'], { type: 'text/plain' });
        await uploadBytes(storageRef, placeholderData);
      }
    } catch (error) {
      console.error('Ошибка при создании папок в Firebase Storage:', error);
    }
  };



  //Функции для управления степпером
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  //Функции для управления степпером
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  //Функции для управления степпером
  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return newBau.status && newBau.kostenstelle_vvo && newBau.bauvorhaben && newBau.ansprechpartner;
      case 1:
        return newBau.ort && newBau.strasse;
      case 2:
        return newBau.ausfurung_von && newBau.ausfurung_bis;
      case 3:
        return selectedFolder;
      default:
        return false;
    }
  };

  const handleDateChange = (date, field) => {
    setNewBau((prev) => ({ ...prev, [field]: date }));
  };

  const handleFolderSelect = (event) => {
    const folderPath = event.target.files[0]?.webkitRelativePath.split('/')[0];
    setSelectedFolder(folderPath);
    setNewBau((prev) => ({ ...prev, kostenstelle_vvo_ordner: folderPath }));
  };

  // Обработчик закрытия Snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Сортировка
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Контейнер для вкладок и строки поиска */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1,
            position: 'relative',
          }}
        >
          {/* Строка поиска */}
          <Box
            ref={searchContainerRef}
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              transition: 'width 0.3s ease',
              width: isSearchOpen ? '500px' : '40px', // Ширина при открытом и закрытом состоянии
              borderRadius: '4px',
            }}
          >
            <TextField
              label="Поиск"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              autoFocus={isSearchOpen}
              inputRef={setSearchInputRef}
              sx={{
                flexGrow: 1,
                transition: 'width 0.3s ease, opacity 0.3s ease',
                width: isSearchOpen ? '100%' : '0px',
                opacity: isSearchOpen ? 1 : 0,
                borderRadius: 1,
              }}
              InputProps={{
                endAdornment: searchQuery ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Очистить поиск"
                      onClick={() => setSearchQuery('')}
                      edge="end"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                style: {
                  paddingRight: '8px',
                },
              }}
            />
            <IconButton
              onClick={() => {
                if (isSearchOpen && !searchQuery) {
                  setIsSearchOpen(false);
                } else if (!isSearchOpen) {
                  setIsSearchOpen(true);
                }
              }}
              size="small"
              sx={{
                transition: 'transform 0.3s ease',
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>

          {/* Вкладки */}
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'transparent',
              padding: '0 16px',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="Status Tabs"
              textColor="primary"
              indicatorColor="primary"
              centered
            >
              <Tab
                icon={<HourglassEmptyIcon />}
                iconPosition="start"
                label="Aktiv"
                value="Aktiv" />

              <Tab
                icon={<PauseCircleOutlineIcon />}
                iconPosition='start'
                label="Inaktiv"
                value="Inaktiv" />

              <Tab
                icon={<CheckCircleOutlineIcon />}
                iconPosition="start"
                label="Abgeschlossen"
                value="Abgeschlossen" />
            </Tabs>
          </Box>
        </Box>

        {/* Таблица */}
        <TableContainer component={MuiPaper} sx={{ mb: 4 }}>
          <Table stickyHeader size={dense ? 'small' : 'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.length > 0 ? (
                visibleRows.map((row, index) => {
                  // Вычисляем приоритет и стили для строки
                  let rowBackgroundColor = index % 2 === 0 ? 'grey.900' : 'grey.800';
                  let ausfurungBisWarning = false;

                  if (currentTab === 'Aktiv') {
                    const priority = calculatePriority(row);

                    switch (priority) {
                      case 1: // Сегодня (красный)
                        rowBackgroundColor = 'rgba(255, 0, 0, 0.7)';
                        break;
                      case 2: // Следующая неделя (оранжевый)
                        rowBackgroundColor = 'rgba(255, 165, 0, 0.7)';
                        break;
                      case 3: // Через две недели (серый)
                        rowBackgroundColor = 'rgba(128, 128, 128, 0.7)';
                        break;
                      case 4: // ausfurung_von в прошлом, до ausfurung_bis осталось меньше 7 дней
                        rowBackgroundColor = 'rgba(0, 128, 0, 0.7)';
                        break;
                      case 5: // ausfurung_von в прошлом
                        rowBackgroundColor = 'rgba(0, 128, 0, 0.3)';
                        break;
                      default:
                        rowBackgroundColor = index % 2 === 0 ? 'grey.900' : 'grey.800';
                    }

                    // Проверяем, нужно ли пометить ячейку ausfurung_bis красным цветом
                    ausfurungBisWarning =
                      priority === 4 &&
                      row.ausfurung_bis &&
                      moment(row.ausfurung_bis, 'DD.MM.YYYY').diff(moment(), 'days') >= 0 &&
                      moment(row.ausfurung_bis, 'DD.MM.YYYY').diff(moment(), 'days') <= 7;
                  }

                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        backgroundColor: rowBackgroundColor,
                        '&:hover .action-icons': {
                          visibility: 'visible',
                          opacity: 1,
                        },
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.1)', // Полупрозрачный чёрный для затемнения
                        },
                      }}
                    >
                      <TableCell align="right">{row.id}</TableCell>
                      <TableCell align="left">{row.kostenstelle_vvo}</TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={row.bauvorhaben.length > 20 ? row.bauvorhaben : ''}
                          placement="top"
                          arrow
                          disableHoverListener={row.bauvorhaben.length <= 20}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {row.bauvorhaben.length > 40
                              ? `${row.bauvorhaben.substring(0, 40)}...`
                              : row.bauvorhaben}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">{row.ansprechpartner}</TableCell>
                      <TableCell align="left">{row.ort}</TableCell>
                      <TableCell align="left">
                        <Tooltip
                          title={row.strasse.length > 20 ? row.strasse : ''}
                          placement="top"
                          arrow
                          disableHoverListener={row.strasse.length <= 20}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {row.strasse.length > 40
                              ? `${row.strasse.substring(0, 40)}...`
                              : row.strasse}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="left">{row.hverbor_datum}</TableCell>
                      <TableCell align="left">{row.ausfurung_von}</TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: ausfurungBisWarning ? 'red' : 'inherit',
                          fontWeight: ausfurungBisWarning ? 'bold' : 'normal',
                        }}
                      >
                        {row.ausfurung_bis}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          className="action-icons"
                          sx={{
                            display: 'flex',
                            gap: 1,
                            justifyContent: 'center',
                            visibility: 'hidden',
                            opacity: 0,
                            transition: 'visibility 0.3s, opacity 0.3s',
                          }}
                        >
                          <Tooltip title="Редактировать стройку">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleEditConstruction(row)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Фото">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleViewPhotos(row)}
                              size="small"
                            >
                              <PhotoIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Часы">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleViewHours(row)}
                              size="small"
                            >
                              <AccessTimeIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Материал">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleManageMaterials(row)}
                              size="small"
                            >
                              <BuildIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    Нет данных для отображения.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={10}
                  count={sortedData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Строк на странице',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        {/* Speed Dial */}
        <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1300 }}>
          <SpeedDial
            ariaLabel="SpeedDial для Bau"
            sx={{
              position: 'absolute',
              bottom: 16,
              right: 16,
              '& .MuiFab-primary': {
                backgroundColor: 'red',
                '&:hover': {
                  backgroundColor: 'darkred',
                },
              },
            }}
            icon={<SpeedDialIcon />}
            onClose={handleSpeedDialClose}
            onOpen={handleSpeedDialOpen}
            open={speedDialOpen}
            direction="up"
          >
            <SpeedDialAction
              icon={<AddIcon />}
              tooltipTitle="Создать стройку"
              onClick={handleDialogOpen}
            />
          </SpeedDial>
        </Box>

        {/* Диалог создания стройки */}
        <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="form-dialog-title"
          >
          <DialogTitle>Создать новую стройку</DialogTitle>
          <DialogContent dividers>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {index === 0 && (
                      <Box sx={{ mt: 2 }}>
                        <FormControl fullWidth margin="dense" size="small">
                          <InputLabel id="status-label">Статус</InputLabel>
                          <Select
                            labelId="status-label"
                            id="status"
                            name="status"
                            value={newBau.status}
                            label="Статус"
                            onChange={handleInputChange}
                          >
                            <MenuItem value="Aktiv">Aktiv</MenuItem>
                            <MenuItem value="Inaktiv">Inaktiv</MenuItem>
                            <MenuItem value="Abgeschlossen">Abgeschlossen</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          label="Kostenstelle VVO"
                          name="kostenstelle_vvo"
                          value={newBau.kostenstelle_vvo}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          margin="dense"
                        />
                        <TextField
                          label="Bauvorhaben"
                          name="bauvorhaben"
                          value={newBau.bauvorhaben}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          margin="dense"
                        />
                        <TextField
                          label="Ansprechpartner"
                          name="ansprechpartner"
                          value={newBau.ansprechpartner}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          margin="dense"
                        />
                        <Box sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                            disabled={!isStepComplete(index)}
                          >
                            Далее
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {index === 1 && (
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          label="Ort"
                          name="ort"
                          value={newBau.ort}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          margin="dense"
                        />
                        <TextField
                          label="Straße"
                          name="strasse"
                          value={newBau.strasse}
                          onChange={handleInputChange}
                          fullWidth
                          size="small"
                          margin="dense"
                        />
                        <Box sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                            disabled={!isStepComplete(index)}
                          >
                            Далее
                          </Button>
                          <Button
                            onClick={handleBack}
                            size="small"
                            sx={{ mt: 1 }}
                          >
                            Назад
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {index === 2 && (
                      <Box sx={{ mt: 2 }}>
                        <DesktopDatePicker
                          label="Ausführung Von"
                          inputFormat="DD.MM.YYYY"
                          value={newBau.ausfurung_von}
                          onChange={(date) => handleDateChange(date, 'ausfurung_von')}
                          renderInput={(params) => <TextField {...params} fullWidth margin="dense" size="small" />}
                        />
                        <DesktopDatePicker
                          label="Ausführung Bis"
                          inputFormat="DD.MM.YYYY"
                          value={newBau.ausfurung_bis}
                          onChange={(date) => handleDateChange(date, 'ausfurung_bis')}
                          renderInput={(params) => <TextField {...params} fullWidth margin="dense" size="small" />}
                        />
                        <Box sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            onClick={handleNext}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                            disabled={!isStepComplete(index)}
                          >
                            Далее
                          </Button>
                          <Button
                            onClick={handleBack}
                            size="small"
                            sx={{ mt: 1 }}
                          >
                            Назад
                          </Button>
                        </Box>
                      </Box>
                    )}
                    {index === 3 && (
                      <Box sx={{ mt: 2 }}>
                        <Button variant="contained" component="label" size="small">
                          Выбрать папку
                          <input type="file" webkitdirectory="true" directory="true" hidden onChange={handleFolderSelect} />
                        </Button>
                        {selectedFolder && (
                          <Typography variant="body2" mt={2}>
                            Выбранная папка: {selectedFolder}
                          </Typography>
                        )}
                        <Box sx={{ mt: 1 }}>
                          <Button
                            variant="contained"
                            onClick={handleSaveBau}
                            size="small"
                            sx={{ mt: 1, mr: 1 }}
                            disabled={!isStepComplete(index)}
                          >
                            Сохранить
                          </Button>
                          <Button
                            onClick={handleBack}
                            size="small"
                            sx={{ mt: 1 }}
                          >
                            Назад
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </DialogContent>
        </Dialog>



        {/* Backdrop для загрузки */}
        {/* ... ваш код Backdrop */}

        {/* Snackbar для уведомлений */}
        {/* ... ваш код Snackbar */}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

// Компонент заголовка таблицы с возможностью сортировки
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              minWidth: headCell.minWidth || 100,
              width: headCell.width || 'auto',
            }}
          >
            {headCell.id !== 'actions' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'Сортировка по убыванию' : 'Сортировка по возрастанию'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.array.isRequired,
};

export default Bau;
