import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Backdrop,
  CircularProgress,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  Snackbar,
  Alert,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper as MuiPaper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormGroup,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableFooter,
  TableSortLabel,
  InputAdornment,
  CssBaseline,
} from '@mui/material';
import {
  Add as AddIcon,
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Photo as PhotoIcon,
  Description as DescriptionIcon,
  PersonAdd as PersonAddIcon,
  Visibility,
  VisibilityOff,
  LocationOn as LocationOnIcon,
  Assignment as AssignmentIcon,
  Search as SearchIcon,
  FiberManualRecord as FiberManualRecordIcon,
  HourglassEmpty as HourglassEmptyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import axios from 'axios';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import PeopleIcon from '@mui/icons-material/People';
import { Grid } from '@mui/material';
import { listAll } from 'firebase/storage';
import { styled, keyframes } from '@mui/material/styles';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Tabs, Tab } from '@mui/material';


import { generateSmallProtocolPDF, generateBigProtocolPDF } from './protocolGenerator';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { getMetadata } from 'firebase/storage';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';




//Реализация добавления маркера для отслеживания водителя
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl:
    'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

//Реализаци мигания индикатора
const blinker = keyframes`
  50% {
    opacity: 0;
  }
`;
const BlinkingIcon = styled(FiberManualRecordIcon)(({ theme }) => ({
  color: 'red',
  animation: `${blinker} 1s linear infinite`,
}));


// Функции для сортировки
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Определение заголовков столбцов
const headCells = [
  { id: 'protocolStatus', label: '', numeric: false, minWidth: 20, width: 30 }, // Новая колонка
  { id: 'id', label: 'ID', numeric: true, minWidth: 30, width: 70 },
  { id: 'kostenstelle', label: 'Kostenstelle', numeric: false, minWidth: 150 },
  { id: 'bauvorhaben', label: 'Bauvorhaben', numeric: false, minWidth: 200 },
  { id: 'ort', label: 'Ort', numeric: false, minWidth: 100 },
  { id: 'strasse', label: 'Strasse', numeric: false, minWidth: 150 },
  { id: 'bauleiter', label: 'Bauleiter', numeric: false, minWidth: 150 },
  { id: 'protokoll', label: 'Protokoll', numeric: false, minWidth: 150 },
  { id: 'beschreibung', label: 'Beschreibung', numeric: false, minWidth: 200 },
  { id: 'kontrollfahrer', label: 'Kontrollfahrer', numeric: false, minWidth: 150 },
  { id: 'haeufigkeit', label: 'Häufigkeit', numeric: false, minWidth: 100 },
  { id: 'sperrungVon', label: 'Sperrung vom', numeric: false, minWidth: 120 },
  { id: 'sperrungBis', label: 'Sperrung bis', numeric: false, minWidth: 120 },
  { id: 'actions', label: '', numeric: false, minWidth: 150 },
];

// Компонент заголовка таблицы с возможностью сортировки
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{
              fontWeight: 'bold',
              minWidth: headCell.minWidth || 100,
              width: headCell.width || 'auto', // Установка фиксированной ширины
            }}
          >
            {headCell.id !== 'protocolStatus' ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}



EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};

// Основной компонент Projects
const Projects = () => {
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [newProject, setNewProject] = useState({
    kostenstelle: '',
    bauvorhaben: '',
    bauleiter: '',
    telephone: '',
    email: '',
    protokoll: '',
    beschreibung: '',
    kontrollfahrer: [],
    ort: '',
    strasse: '',
    haeufigkeit: '',
    sperrungVon: null,
    sperrungBis: null,
    vrao: null,
    vraoURL: '',
    vraoFileName: '',
    vzp: [],
    vzpURLs: [],
    status: 'active',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [bauleiterList, setBauleiterList] = useState([]);
  const [fahrerList, setFahrerList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [dense, setDense] = useState(true);

  //Добавление Состояния для Вкладок
  const [selectedTab, setSelectedTab] = useState('active');

  // Состояния для фильтров
  const [filterFromDate, setFilterFromDate] = useState(null);
  const [filterToDate, setFilterToDate] = useState(null);

  // Новое сосотояние для отобржанеия статуса незавершенного протокола
  const [protocolStatus, setProtocolStatus] = useState({});

  // Состояние для отсортированных и отфильтрованных URL фотографий
  const [filteredPhotoUrls, setFilteredPhotoUrls] = useState([]);

  //Добавление состояний для управления диалогом и фотографиями
  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);
  const [photoUrls, setPhotoUrls] = useState([]); // [{ url: '...', timeCreated: Date }, ...]
  const [currentPhotoProject, setCurrentPhotoProject] = useState(null);
  const [photosLoading, setPhotosLoading] = useState(false);

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  //состояние для хранения запроса поиска
  const [searchQuery, setSearchQuery] = useState('');

  // Состояние для управления открытием строки поиска
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // Ссылка на контейнер поиска
  const searchContainerRef = useRef(null);
  // Создаем реф для TextField
  const searchInputRef = useRef(null);

  //Добавляем состояние загрузки
  const [isLoading, setIsLoading] = useState(false);

  // Состояния для редактирования
  const [isEditing, setIsEditing] = useState(false);
  const [editingProjectId, setEditingProjectId] = useState(null);

  // Состояние для диалога подтверждения удаления
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const [deleteError, setDeleteError] = useState('');

  //состояние для хранения файлов, которые необходимо удалить
  const [filesToDelete, setFilesToDelete] = useState([]);

  // Новые состояния
  const [trackDialogOpen, setTrackDialogOpen] = useState(false);
  const [driverLocation, setDriverLocation] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);

  // Новые состояния для добавления водителя
  const [driverDialogOpen, setDriverDialogOpen] = useState(false);
  const [newDriver, setNewDriver] = useState({
    name: '',
    password: '',
    confirmPassword: '',
    telephone: '',
  });

  // Состояния для паролей и их видимости
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Новые состояния для управления водителями
  const [manageDriversDialogOpen, setManageDriversDialogOpen] = useState(false);
  const [editDriverDialogOpen, setEditDriverDialogOpen] = useState(false);
  const [deleteDriverDialogOpen, setDeleteDriverDialogOpen] = useState(false);
  const [driverToEdit, setDriverToEdit] = useState(null);
  const [driverToDelete, setDriverToDelete] = useState(null);

  //Состояния для диалогов протоколов
  const [protocolsDialogOpen, setProtocolsDialogOpen] = useState(false);
  const [protocolsData, setProtocolsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // Состояния для диалога всех протоколов
  const [allProtocolsDialogOpen, setAllProtocolsDialogOpen] = useState(false);
  const [allProtocolsData, setAllProtocolsData] = useState([]);
  const [filteredProtocolsData, setFilteredProtocolsData] = useState([]);

  // Состояния для фильтров и поиска в диалоге протоколов
  const [protocolsFilterFromDate, setProtocolsFilterFromDate] = useState(null);
  const [protocolsFilterToDate, setProtocolsFilterToDate] = useState(null);
  const [protocolsSearchQuery, setProtocolsSearchQuery] = useState('');

  //Состояния для принудительного закрытия
  const [closeProtocolDialogOpen, setCloseProtocolDialogOpen] = useState(false);
  const [protocolToClose, setProtocolToClose] = useState(null);

  // новые состояния для управления диалогом существующего проекта
  const [existingProjectDialogOpen, setExistingProjectDialogOpen] = useState(false);
  const [existingProject, setExistingProject] = useState(null);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Состояния для сортировки
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // URL вашего API
  const API_URL = 'https://us-central1-vigilant-mix-403621.cloudfunctions.net/api';

  // Получение списков из бэкенда
  const fetchBauleiter = async () => {
    try {
      const response = await axios.get(`${API_URL}/bauleiter`);
      setBauleiterList(response.data);
    } catch (error) {
      console.error('Ошибка при получении Bauleiter:', error);
      setSnackbarMessage('Bauleiter konnte nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchFahrer = async () => {
    try {
      const response = await axios.get(`${API_URL}/fahrer`);
      setFahrerList(response.data);
    } catch (error) {
      console.error('Ошибка при получении Fahrer:', error);
      setSnackbarMessage('Fahrer konnte nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchAllUberBauProjects = async () => {
    try {
      const response = await axios.get(`${API_URL}/show_all_uber_bau`);
      setProjects(response.data);
    } catch (error) {
      console.error('Ошибка при получении всех проектов из uber_bau_bd:', error);
      setSnackbarMessage('Projekte konnten nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const fetchProtocolStatuses = useCallback(async () => {
    const statusMap = {};
    try {
      const protocolPromises = projects.map(async (project) => {
        const response = await axios.get(`${API_URL}/protocols/${project.id}`);
        const protocols = response.data;
        const incompleteProtocols = protocols.filter(protocol => !protocol.end_time);
        if (incompleteProtocols.length > 0) {
          const driverNames = incompleteProtocols.map(protocol => protocol.kontrollfahrer_name);
          statusMap[project.id] = driverNames;
        }
      });
      await Promise.all(protocolPromises);
      setProtocolStatus(statusMap);
    } catch (error) {
      console.error('Ошибка при получении протоколов:', error);
      // Опционально: Можно показать уведомление о проблемах при получении протоколов
    }
  }, [projects, API_URL]); // Укажите все зависимости

  //Сброс страницы при смене вкладки или поискового запроса
  useEffect(() => {
    setPage(0);
  }, [selectedTab, searchQuery]);

  //Создаем интервал для обновления таблицы в реальном времени
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (projects.length > 0) {
        fetchProtocolStatuses();
      }
    }, 60000); // 10000 миллисекунд = 60 секунд

    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [projects, fetchProtocolStatuses]);

  //Автоматическое переключение вкладки при поиске
  useEffect(() => {
    if (searchQuery) {
      setSelectedTab('all');
    } else {
      setSelectedTab('active'); // или другое значение по умолчанию
    }
  }, [searchQuery]);


  useEffect(() => {
    // Загрузка проектов при монтировании компонента
    fetchAllUberBauProjects();
    fetchBauleiter();
    fetchFahrer();
  }, []);

  //установки фокуса при открытии поля поиска
  useEffect(() => {
    if (isSearchOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isSearchOpen]);


  const handleSpeedDialOpen = () => setSpeedDialOpen(true);
  const handleSpeedDialClose = () => setSpeedDialOpen(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
    handleSpeedDialClose();
    setActiveStep(0);
    setNewProject({
      kostenstelle: '',
      bauvorhaben: '',
      bauleiter: '',
      telephone: '',
      email: '',
      protokoll: '',
      beschreibung: '',
      kontrollfahrer: [],
      ort: '',
      strasse: '',
      haeufigkeit: '',
      sperrungVon: null,
      sperrungBis: null,
      vrao: null,
      vraoURL: '',
      vraoFileName: '',
      vzp: [],
      vzpURLs: [],
      status: 'active',
    });
    fetchBauleiter();
    fetchFahrer();
    setIsEditing(false);
    setEditingProjectId(null);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsEditing(false);
    setEditingProjectId(null);
    setFilesToDelete([]); // Очищаем список файлов для удаления
  };

  //функцию для обработки изменений в строке поиска
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Сбросить текущую страницу при новом поиске
  };

  // Обработчик кликов вне области поиска
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        if (!searchQuery) { // Только если строка поиска пустая
          setIsSearchOpen(false);
        }
      }
    };

    // Добавляем слушатель кликов
    document.addEventListener('mousedown', handleClickOutside);

    // Удаляем слушатель при размонтировании
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchQuery]);

  // Обработчик закрытия диалога протоколов
  const handleProtocolsDialogClose = () => {
    setProtocolsDialogOpen(false);
    setProtocolsData([]);
    setSelectedProject(null);
  };

  // Обновлённая функция downloadSmallProtocol
  const downloadSmallProtocol = async (protocolId) => {
    setIsLoading(true); // Начало загрузки
    try {
      // Получаем данные протокола с бэкенда
      const response = await axios.get(`${API_URL}/protocols/data/${protocolId}`);
      const protocol = response.data;

      // Генерируем PDF на фронтенде
      await generateSmallProtocolPDF(protocol);

      // После успешной генерации можно показать уведомление (опционально)
      setSnackbarMessage('Kleines Protokoll erfolgreich heruntergeladen!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Ошибка при генерации протокола:', error);
      setSnackbarMessage('Protokoll konnte nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // Завершение загрузки
    }
  };


  // Обновлённая функция downloadBigProtocol
  const downloadBigProtocol = async (protocolId) => {
    setIsLoading(true); // Начало загрузки
    try {
      // Получаем данные протокола с бэкенда
      const response = await axios.get(`${API_URL}/protocols/data/${protocolId}`);
      const protocol = response.data;

      // Генерируем PDF на фронтенде
      await generateBigProtocolPDF(protocol);

      // После успешной генерации можно показать уведомление (опционально)
      setSnackbarMessage('Großes Protokoll erfolgreich heruntergeladen!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Ошибка при генерации протокола:', error);
      setSnackbarMessage('Protokoll konnte nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false); // Завершение загрузки
    }
  };

  //Загружаем все протоколы при открытии диалога
  const handleViewAllProtocols = () => {
    setAllProtocolsDialogOpen(true);
    handleSpeedDialClose();
    fetchAllProtocols();
  };

  //функцию для загрузки всех протоколов
  const fetchAllProtocols = async () => {
    setIsLoading(true);
    try {
      const params = {};

      if (protocolsFilterFromDate) {
        params.fromDate = protocolsFilterFromDate.format('YYYY-MM-DD');
      }
      if (protocolsFilterToDate) {
        // Создаём копию даты и добавляем один день
        const adjustedToDate = protocolsFilterToDate.clone().add(1, 'days');
        params.toDate = adjustedToDate.format('YYYY-MM-DD');
      }
      if (protocolsSearchQuery) {
        params.searchQuery = protocolsSearchQuery;
      }

      const queryString = new URLSearchParams(params).toString();

      const response = await axios.get(`${API_URL}/protocols?${queryString}`);
      setAllProtocolsData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Ошибка при загрузке протоколов:', error);
      setSnackbarMessage('Protokolle konnten nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  // Функция для открытия диалога закрытия протокола
  const handleCloseProtocolClick = (protocol) => {
    setProtocolToClose(protocol);
    setCloseProtocolDialogOpen(true);
  };

  // Функция для подтверждения закрытия протокола
  const handleConfirmCloseProtocol = async () => {
    if (!protocolToClose) return;
    try {
      const response = await axios.put(`${API_URL}/protocols/${protocolToClose.id}/close`);
      if (response.status === 200) {
        setSnackbarMessage('Protokoll erfolgreich geschlossen!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        // Обновляем список протоколов
        if (selectedProject) {
          fetchProtocols(selectedProject.id);
        } else {
          fetchAllProtocols();
        }
        setCloseProtocolDialogOpen(false);
      } else {
        throw new Error('Fehler beim Schließen des Protokolls');
      }
    } catch (error) {
      console.error('Fehler beim Schließen des Protokolls:', error);
      setSnackbarMessage('Das Protokoll konnte nicht geschlossen werden. Bitte versuchen Sie es erneut.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setCloseProtocolDialogOpen(false);
    }
  };

  // Функция для отмены закрытия протокола
  const handleCancelCloseProtocol = () => {
    setCloseProtocolDialogOpen(false);
    setProtocolToClose(null);
  };

  // Функция для применения фильтров
  const applyProtocolsFilters = () => {
    let filtered = [...allProtocolsData];

    // Фильтр по дате
    if (protocolsFilterFromDate) {
      filtered = filtered.filter((protocol) =>
        moment(protocol.zeit).isSameOrAfter(protocolsFilterFromDate, 'day')
      );
    }
    if (protocolsFilterToDate) {
      filtered = filtered.filter((protocol) =>
        moment(protocol.zeit).isSameOrBefore(protocolsFilterToDate, 'day')
      );
    }

    // Поиск по тексту
    if (protocolsSearchQuery) {
      const query = protocolsSearchQuery.toLowerCase();
      filtered = filtered.filter(
        (protocol) =>
          protocol.kostenstelle?.toLowerCase().includes(query) ||
          protocol.ort?.toLowerCase().includes(query) ||
          protocol.strasse?.toLowerCase().includes(query) ||
          protocol.kontrollfahrer_name?.toLowerCase().includes(query)
      );
    }

    setFilteredProtocolsData(filtered);
  };

  // Обработчик очистки фильтров
  const clearProtocolsFilters = () => {
    setProtocolsFilterFromDate(null);
    setProtocolsFilterToDate(null);
    setProtocolsSearchQuery('');
  };

  useEffect(() => {
    if (allProtocolsData.length > 0) {
      applyProtocolsFilters();
    }
  }, [allProtocolsData, protocolsFilterFromDate, protocolsFilterToDate, protocolsSearchQuery]);



  // Обработчик изменения полей ввода
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prev) => ({ ...prev, [name]: value }));
  };

  // Обработчик изменения списка Kontrollfahrer
  const handleKontrollfahrerChange = (id) => {
    setNewProject((prev) => {
      if (prev.kontrollfahrer.includes(id)) {
        return {
          ...prev,
          kontrollfahrer: prev.kontrollfahrer.filter((fahrerId) => fahrerId !== id),
        };
      } else {
        return { ...prev, kontrollfahrer: [...prev.kontrollfahrer, id] };
      }
    });
  };

  // Обработчик загрузки файла VRAO
  const handleVRAOUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProject((prev) => ({ ...prev, vrao: file, vraoFileName: file.name }));
    }
  };

  // Обработчик загрузки файлов VZP
  const handleVZPUpload = (e) => {
    const files = Array.from(e.target.files);
    const newFiles = files.map((file) => ({ file, name: file.name }));
    setNewProject((prev) => ({ ...prev, vzp: [...prev.vzp, ...newFiles] }));
  };

  // Обработчик удаления файла VZP
  const handleVZPDelete = (index) => {
    setNewProject((prev) => {
      const newVZP = [...prev.vzp];
      newVZP.splice(index, 1);
      return { ...prev, vzp: newVZP };
    });
  };

  // Обработчик удаления существующего VZP URL
  const handleVZPURLDelete = (index) => {
    setNewProject((prev) => {
      const newVZPURLs = [...prev.vzpURLs];
      const [removedFile] = newVZPURLs.splice(index, 1);
      // Реконструируем путь к файлу в Firebase Storage
      const storagePath = `Uberwachung/${prev.kostenstelle}/VZP/${removedFile.name}`;
      // Добавляем файл в список файлов для удаления
      setFilesToDelete((prevFiles) => [...prevFiles, { path: storagePath }]);
      return { ...prev, vzpURLs: newVZPURLs };
    });
  };

  //Реализация функции handleVRAODelete
  const handleVRAODelete = () => {
    if (newProject.vrao) {
      // Если VRAO файл только что загружен и не сохранен в базе
      setNewProject((prev) => ({ ...prev, vrao: null, vraoFileName: '' }));
    } else if (newProject.vraoURL && newProject.vraoFileName) {
      // Если VRAO файл уже сохранен в базе данных
      const storagePath = `Uberwachung/${newProject.kostenstelle}/VRAO/${newProject.vraoFileName}`;
      // Добавляем файл в список файлов для удаления
      setFilesToDelete((prevFiles) => [...prevFiles, { path: storagePath }]);
      // Удаляем файл из состояния проекта
      setNewProject((prev) => ({
        ...prev,
        vraoURL: '',
        vraoFileName: '',
      }));
    }
  };



  // Обработчик сохранения проекта
  const handleSaveProject = async () => {
    let uploadedFiles = [];
    let photoPath = '';
    setIsLoading(true);
    try {
      // Проверка обязательного поля kostenstelle
      if (!newProject.kostenstelle) {
        throw new Error('Kostenstelle ist erforderlich.');
      }

      const kostenstelle = newProject.kostenstelle;

      // Определение базовых путей для загрузки файлов
      const basePath = `Uberwachung/${kostenstelle}`;
      const vraoPath = `${basePath}/VRAO`;
      const vzpPath = `${basePath}/VZP`;
      photoPath = `${basePath}/Photo`;

      // Функция для загрузки одного файла и получения его URL
      const uploadFile = async (file, path) => {
        const fileRef = ref(storage, `${path}/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        return { downloadURL, path: `${path}/${file.name}`, name: file.name };
      };

      // Функция для создания папки Photo путём загрузки пустого файла
      const createEmptyPhotoFolder = async (path) => {
        const placeholderFile = new Blob([''], { type: 'text/plain' });
        const fileRef = ref(storage, `${path}/.keep`);
        await uploadBytes(fileRef, placeholderFile);
      };

      // Загрузка VRAO файла (один файл)
      let updatedVRAOURL = '';
      let updatedVRAOFileName = '';
      if (newProject.vrao) {
        // Если есть новый VRAO файл для загрузки
        const vraoUpload = await uploadFile(newProject.vrao, vraoPath);
        updatedVRAOURL = vraoUpload.downloadURL;
        updatedVRAOFileName = vraoUpload.name;
        uploadedFiles.push(vraoUpload);
      } else if (newProject.vraoURL && newProject.vraoFileName) {
        // Если VRAO файл не изменялся
        updatedVRAOURL = newProject.vraoURL;
        updatedVRAOFileName = newProject.vraoFileName;
      } else {
        // Если VRAO файл был удален и нет нового файла
        updatedVRAOURL = '';
        updatedVRAOFileName = '';
      }


      // Загрузка VZP файлов (массив файлов)
      let updatedVZPURLs = newProject.vzpURLs || [];
      if (newProject.vzp.length > 0) {
        const vzpUploads = await Promise.all(
          newProject.vzp.map(({ file }) => uploadFile(file, vzpPath)),
        );
        const vzpURLs = vzpUploads.map((upload) => ({
          url: upload.downloadURL,
          name: upload.name,
        }));
        uploadedFiles = [...uploadedFiles, ...vzpUploads];
        updatedVZPURLs = [...updatedVZPURLs, ...vzpURLs];
      }

      // Создание папки Photo путём загрузки пустого файла
      await createEmptyPhotoFolder(photoPath);

      // Подготовка данных для отправки в базу данных
      const projectData = {
        kostenstelle_vvo: newProject.kostenstelle,
        bauvorhaben: newProject.bauvorhaben,
        ort: newProject.ort,
        strasse: newProject.strasse,
        sperrungVon: newProject.sperrungVon
          ? moment(newProject.sperrungVon).format('YYYY-MM-DD')
          : null,
        sperrungBis: newProject.sperrungBis
          ? moment(newProject.sperrungBis).format('YYYY-MM-DD')
          : null,
        bauleiter: newProject.bauleiter,
        telephone: newProject.telephone,
        email: newProject.email,
        protokoll: newProject.protokoll,
        beschreibung: newProject.beschreibung,
        kontrollfahrer: newProject.kontrollfahrer,
        haeufigkeit: newProject.haeufigkeit,
        vraoDownloadURL: updatedVRAOURL,
        vraoFileName: updatedVRAOFileName,
        vzpDownloadURLs: updatedVZPURLs,
        status: newProject.status || 'active',
      };

      if (isEditing) {
        // Отправляем PUT-запрос для обновления проекта
        const response = await axios.put(
          `${API_URL}/uber_projects/${editingProjectId}`,
          projectData,
        );

        if (response.status === 200) {
          setSnackbarMessage('Projekt erfolgreich aktualisiert!');
          fetchAllUberBauProjects();
          // После успешного обновления удаляем файлы
          await deleteFilesFromFirebase();
        } else {
          throw new Error('Fehler beim Aktualisieren des Projekts');
        }
      } else {
        // Отправка данных в базу данных через Firebase Functions
        const response = await axios.post(`${API_URL}/uber_new_project`, projectData);

        if (response.status === 201) {
          setSnackbarMessage('Projekt erfolgreich erstellt!');
          fetchAllUberBauProjects();
          // После успешного создания удаляем файлы
          await deleteFilesFromFirebase();
        } else {
          throw new Error('Fehler beim Erstellen des Projekts');
        }
      }

      // Общие действия после успешного сохранения
      setIsLoading(false);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setNewProject({
        kostenstelle: '',
        bauvorhaben: '',
        bauleiter: '',
        telephone: '',
        email: '',
        protokoll: '',
        beschreibung: '',
        kontrollfahrer: [],
        ort: '',
        strasse: '',
        haeufigkeit: '',
        sperrungVon: null,
        sperrungBis: null,
        vrao: null,
        vraoURL: '',
        vraoFileName: '',
        vzp: [],
        vzpURLs: [],
        status: 'active',
      });
      setActiveStep(0);
      handleDialogClose();
    } catch (error) {
      console.error('Fehler beim Speichern des Projekts:', error);
      setSnackbarMessage('Das Projekt konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);

      // Дополнительная логика для удаления загруженных файлов при ошибке
      if (uploadedFiles.length > 0) {
        try {
          await Promise.all(
            uploadedFiles.map(async (file) => {
              const fileRef = ref(storage, file.path);
              await deleteObject(fileRef);
            }),
          );
          console.log('Загруженные файлы были удалены из Firebase Storage.');
        } catch (deleteError) {
          console.error('Ошибка при удалении файлов:', deleteError);
        }
      }

      // Удаление пустой папки Photo путем удаления файла .keep
      try {
        const keepFileRef = ref(storage, `${photoPath}/.keep`);
        await deleteObject(keepFileRef);
        console.log('Папка Photo была удалена.');
      } catch (keepDeleteError) {
        console.error('Ошибка при удалении файла .keep:', keepDeleteError);
      }
    }
  };

  //Удаление файлов из firebase storage
  const deleteFilesFromFirebase = async () => {
    if (filesToDelete.length > 0) {
      try {
        await Promise.all(
          filesToDelete.map(async (file) => {
            const fileRef = ref(storage, file.path);
            await deleteObject(fileRef);
          }),
        );
        console.log('Удаленные файлы были удалены из Firebase Storage.');
        // Очищаем список файлов для удаления
        setFilesToDelete([]);
      } catch (deleteError) {
        console.error('Ошибка при удалении файлов:', deleteError);
      }
    }
  };

  // Функция для получения протоколов с бэкенда
  const fetchProtocols = async (projectId) => {
    try {
      const response = await axios.get(`${API_URL}/protocols/${projectId}`);

      // Предполагаем, что response.data - это массив протоколов
      // Сортируем протоколы по полю 'zeit' в порядке убывания
      const sortedProtocols = [...response.data].sort((a, b) => {
        const dateA = new Date(a.zeit);
        const dateB = new Date(b.zeit);
        return dateB - dateA; // Для сортировки от новых к старым
      });

      setProtocolsData(sortedProtocols);
    } catch (error) {
      console.error('Ошибка при получении протоколов:', error);
      setSnackbarMessage('Protokolle konnten nicht geladen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  //Функция для применения фильтров для сортировки фото в диалоговом окне для фото
  const applyFilters = () => {
    let filtered = [...photoUrls];

    // Фильтрация по дате
    if (filterFromDate || filterToDate) {
      filtered = filtered.filter(({ timeCreated }) => {
        let isValid = true;
        if (filterFromDate) {
          isValid = isValid && timeCreated >= filterFromDate.toDate();
        }
        if (filterToDate) {
          isValid = isValid && timeCreated <= filterToDate.toDate();
        }
        return isValid;
      });
    }

    setFilteredPhotoUrls(filtered);
  };

  // Функция для очистки фильтров
  const clearFilters = () => {
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilteredPhotoUrls(photoUrls); // Возвращаем все фотографии
  };



  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Обработчики Stepper
  // Обработчики Stepper
  const handleNext = () => {
    if (activeStep === 0) {
      const { kostenstelle, bauvorhaben, bauleiter, email, protokoll, beschreibung } = newProject;
      if (!kostenstelle || !bauvorhaben || !bauleiter || !email || !protokoll || !beschreibung) {
        setSnackbarMessage('Bitte füllen Sie alle Felder in diesem Schritt aus.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setSnackbarMessage('Bitte geben Sie eine gültige E-Mail-Adresse ein.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      // Новая проверка на существующий kostenstelle, исключая текущий проект при редактировании
      const existingProj = projects.find(
        (project) =>
          project.kostenstelle === kostenstelle && (!isEditing || project.id !== editingProjectId)
      );
      if (existingProj) {
        setExistingProjectDialogOpen(true);
        setExistingProject(existingProj);
        return;
      }
    }

    if (activeStep === 1 && newProject.kontrollfahrer.length === 0) {
      setSnackbarMessage('Bitte wählen Sie mindestens einen Mitarbeiter aus.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (activeStep === 2) {
      const { ort, strasse, haeufigkeit, sperrungVon, sperrungBis } = newProject;
      if (!ort || !strasse || !haeufigkeit || !sperrungVon || !sperrungBis) {
        setSnackbarMessage('Bitte füllen Sie alle Felder in diesem Schritt aus.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };



  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setNewProject({
      kostenstelle: '',
      bauvorhaben: '',
      bauleiter: '',
      telephone: '',
      email: '',
      protokoll: '',
      beschreibung: '',
      kontrollfahrer: [],
      ort: '',
      strasse: '',
      haeufigkeit: '',
      sperrungVon: null,
      sperrungBis: null,
      vrao: null,
      vraoURL: '',
      vraoFileName: '',
      vzp: [],
      vzpURLs: [],
      status: 'active',
    });
  };

  // Обработчик открытия диалога добавления водителя
  const handleAddDriverOpen = () => {
    setDriverDialogOpen(true);
    handleSpeedDialClose();
    // Сброс формы водителя
    setNewDriver({
      name: '',
      password: '',
      confirmPassword: '',
      telephone: '',
    });
  };

  // Обработчик закрытия диалога добавления водителя
  const handleDriverDialogClose = () => {
    setDriverDialogOpen(false);
  };

  // Обработчик изменения полей ввода для водителя
  const handleDriverInputChange = (e) => {
    const { name, value } = e.target;
    setNewDriver((prev) => ({ ...prev, [name]: value }));
  };

  // Обработчик сохранения водителя
  const handleSaveDriver = async () => {
    const { name, password, confirmPassword, telephone } = newDriver;

    // Валидация полей
    if (!name || !password || !confirmPassword || !telephone) {
      setSnackbarMessage('Bitte füllen Sie alle Felder aus.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarMessage('Die Passwörter stimmen nicht überein.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setIsLoading(true);
    try {
      // Отправка данных на сервер
      const response = await axios.post(`${API_URL}/drivers`, { name, password, telephone });

      if (response.status === 201) {
        setSnackbarMessage('Fahrer erfolgreich hinzugefügt!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleDriverDialogClose();
        // Обновляем список водителей
        fetchFahrer();
        setIsLoading(false);
      } else {
        throw new Error('Ошибка при добавлении водителя');
      }
    } catch (error) {
      console.error('Ошибка при добавлении водителя:', error);
      setSnackbarMessage('Der Fahrer konnte nicht hinzugefügt werden. Bitte versuchen Sie es erneut.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  // Обработчики кнопок таблицы
  const handleEdit = (project) => {
    console.log('Редактировать проект:', project);
    setIsEditing(true);
    setEditingProjectId(project.id);

    setNewProject({
      kostenstelle: project.kostenstelle,
      bauvorhaben: project.bauvorhaben,
      bauleiter: project.bauleiter,
      telephone: project.telephone,
      email: project.email,
      protokoll: project.protokoll,
      beschreibung: project.beschreibung,
      kontrollfahrer: project.kontrollfahrer || [],
      ort: project.ort,
      strasse: project.strasse,
      haeufigkeit: project.haeufigkeit,
      sperrungVon: project.sperrungVonISO ? moment(project.sperrungVonISO) : null,
      sperrungBis: project.sperrungBisISO ? moment(project.sperrungBisISO) : null,
      vrao: null,
      vraoURL: project.vrao_url || '',
      vraoFileName: project.vrao_filename || '',
      vzp: [],
      vzpURLs: project.vzp_urls || [],
      status: project.status || 'active',
    });
    setActiveStep(0);
    setDialogOpen(true);
  };

  //обработчик загрузки фото из firebase storage
  const handleViewPhoto = (project) => {
    setCurrentPhotoProject(project);
    setPhotoDialogOpen(true);
    setPhotoUrls([]); // Очистка предыдущих URL
  };

  // Новый обработчик для очистки поиска
  const handleClearSearch = () => {
    setSearchQuery('');
    setFilteredPhotoUrls(photoUrls); // Возвращаем все фотографии, если применимо
    // Удаляем автоматическую установку selectedTab, чтобы позволить пользователю выбирать вкладку
  };


  //функция загрузки фото из firebase storage
  useEffect(() => {
    const fetchPhotos = async () => {
      if (photoDialogOpen && currentPhotoProject) {
        setPhotosLoading(true);
        try {
          const photosRef = ref(storage, `Uberwachung/${currentPhotoProject.kostenstelle}/Photo`);
          const listResult = await listAll(photosRef);

          // Фильтрация файлов: исключаем '.keep' и оставляем только изображения
          const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
          let filteredItems = listResult.items.filter((itemRef) => {
            const lowerCaseName = itemRef.name.toLowerCase();
            const isKeepFile = lowerCaseName === '.keep';
            const isImage = imageExtensions.some((ext) => lowerCaseName.endsWith(ext));
            return !isKeepFile && isImage;
          });

          // Получаем метаданные для каждого файла
          const itemsWithMetadata = await Promise.all(
            filteredItems.map(async (itemRef) => {
              const metadata = await getMetadata(itemRef);
              return {
                url: await getDownloadURL(itemRef),
                timeCreated: new Date(metadata.timeCreated),
              };
            })
          );

          // Сортировка от новых к старым по timeCreated
          itemsWithMetadata.sort((a, b) => b.timeCreated - a.timeCreated);

          setPhotoUrls(itemsWithMetadata);
          setFilteredPhotoUrls(itemsWithMetadata); // Изначально все фотографии отображаются
        } catch (error) {
          console.error('Ошибка при загрузке фотографий:', error);
          setSnackbarMessage('Fotos konnten nicht geladen werden.');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        } finally {
          setPhotosLoading(false);
        }
      }
    };

    fetchPhotos();
  }, [photoDialogOpen, currentPhotoProject]);



  useEffect(() => {
    // Если фильтры изменились, автоматически применяем их
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterFromDate, filterToDate, photoUrls]);

  // Обработчик открытия диалога протоколов
  const handleViewProtocols = (project) => {
    setSelectedProject(project);
    fetchProtocols(project.id);
    setProtocolsDialogOpen(true);
  };

  // Функция для получения имени Bauleiter по ID
  const getBauleiterName = (id) => {
    const bauleiter = bauleiterList.find((b) => b.id === id);
    return bauleiter ? bauleiter.name : 'Неизвестно';
  };

  // Функция для получения имен Kontrollfahrer по массиву ID
  const getKontrollfahrerNames = (ids) => {
    return fahrerList
      .filter((f) => ids.includes(f.id))
      .map((f) => f.name);
  };


  // Обработчик удаления проекта
  const handleDeleteProject = () => {
    setDeletePassword('');
    setDeleteError('');
    setDeleteDialogOpen(true);
  };


  const confirmDeleteProject = async () => {
    if (!editingProjectId) return;
    setIsLoading(true);
    try {
      // Отправляем DELETE-запрос с паролем в теле запроса
      const response = await axios.delete(`${API_URL}/uber_projects/${editingProjectId}`, {
        data: { password: deletePassword },
      });

      if (response.status === 200) {
        setSnackbarMessage('Projekt erfolgreich gelöscht!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleDialogClose();
        fetchAllUberBauProjects();
        setDeleteDialogOpen(false);
      } else {
        throw new Error('Fehler beim Löschen des Projekts');
      }
    } catch (error) {
      console.error('Fehler beim Löschen des Projekts:', error);
      if (error.response && error.response.status === 401) {
        // Неверный пароль
        setDeleteError('Das Passwort ist falsch. Bitte wenden Sie sich an Ihren Systemadministrator.');
      } else {
        setSnackbarMessage('Das Projekt konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        setDeleteDialogOpen(false);
      }
    } finally {
      setIsLoading(false);
    }
  };


  const cancelDeleteProject = () => {
    setDeleteDialogOpen(false);
  };

  // Сортировка проектов перед отображением
  const sortedProjects = React.useMemo(() => {
    return [...projects].sort(getComparator(order, orderBy));
  }, [projects, order, orderBy]);


  // Функция для фильтрации проектов по статусу и поисковому запросу
  const filteredProjects = React.useMemo(() => {
    return sortedProjects.filter((project) => {
      // При поиске включаем все проекты независимо от статуса
      const statusMatch = searchQuery ? true : project.status === selectedTab;

      // Фильтрация по поисковому запросу (ограничьте поля для релевантности)
      const searchMatch =
        !searchQuery ||
        ['kostenstelle', 'bauvorhaben', 'ort', 'strasse', 'beschreibung', 'haeufigkeit'].some((field) =>
          project[field]?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );

      return statusMatch && searchMatch;
    });
  }, [searchQuery, sortedProjects, selectedTab]);



  // Пагинация для отфильтрованных проектов
  const visibleRows = React.useMemo(
    () =>
      filteredProjects.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filteredProjects, page, rowsPerPage]
  );


  // Тема (оставляем как есть)
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#f48fb1',
      },
      background: {
        default: '#121212',
        paper: '#1d1d1d',
      },
      text: {
        primary: '#ffffff',
        secondary: '#b0b0b0',
      },
    },
    typography: {
      fontSize: 14,
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            tableLayout: 'fixed', // Устанавливаем фиксированную компоновку таблицы
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            textAlign: 'center',
          },
          head: {
            backgroundColor: 'grey',
            color: 'black !important',
            fontWeight: 'bold',
            padding: '8px 6px',
            textAlign: 'center',
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 8,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '6px 12px',
            fontSize: '0.875rem',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            padding: '16px 24px',
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiStepContent: {
        styleOverrides: {
          root: {
            marginTop: 8,
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 8,
          },
        },
      },
      MuiFormGroup: {
        styleOverrides: {
          root: {
            marginTop: 8,
            marginBottom: 8,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
      // Добавляем стили для полосы прокрутки
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            scrollbarWidth: 'none',
          },
        },
      },
    },
  });
  
  // Обработчик открытия диалога управления водителями
  const handleManageDriversOpen = () => {
    setManageDriversDialogOpen(true);
    handleSpeedDialClose();
  };

  const handleManageDriversClose = () => {
    setManageDriversDialogOpen(false);
  };

  // Обработчик редактирования водителя
  const handleEditDriver = (driver) => {
    setDriverToEdit({
      ...driver,
      password: '',
      confirmPassword: '',
    });
    setEditDriverDialogOpen(true);
  };

  const handleEditDriverDialogClose = () => {
    setEditDriverDialogOpen(false);
    setDriverToEdit(null);
  };

  const handleEditDriverInputChange = (e) => {
    const { name, value } = e.target;
    setDriverToEdit((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEditedDriver = async () => {
    const { id, name, password, confirmPassword, telephone } = driverToEdit;

    if (!name || !telephone) {
      setSnackbarMessage('Bitte füllen Sie alle Felder aus.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    if (password && password !== confirmPassword) {
      setSnackbarMessage('Die Passwörter stimmen nicht überein');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const data = {
        name,
        telephone,
      };
      if (password) {
        data.password = password;
      }

      const response = await axios.put(`${API_URL}/drivers/${id}`, data);

      if (response.status === 200) {
        setSnackbarMessage('Fahrer erfolgreich aktualisiert!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleEditDriverDialogClose();
        fetchFahrer();
      } else {
        throw new Error('Ошибка при обновлении водителя');
      }
    } catch (error) {
      console.error('Ошибка при обновлении водителя:', error);
      setSnackbarMessage('Der Fahrer konnte nicht aktualisiert werden. Bitte versuchen Sie es erneut.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Обработчик удаления водителя
  const handleDeleteDriver = (driver) => {
    setDriverToDelete(driver);
    setDeleteDriverDialogOpen(true);
  };

  const handleConfirmDeleteDriver = async () => {
    const { id, name } = driverToDelete;
    setIsLoading(true);
    try {
      const response = await axios.delete(`${API_URL}/drivers/${id}`);
      if (response.status === 200) {
        setSnackbarMessage(`Fahrer ${name} erfolgreich gelöscht!`);
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        handleCancelDeleteDriver();
        fetchFahrer();
        setIsLoading(false);
      } else {
        throw new Error('Ошибка при удалении водителя');
      }
    } catch (error) {
      console.error('Ошибка при удалении водителя:', error);
      setSnackbarMessage('Der Fahrer konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setIsLoading(false);
    }
  };

  const handleCancelDeleteDriver = () => {
    setDeleteDriverDialogOpen(false);
    setDriverToDelete(null);
  };

  // Обработчик отслеживания местоположения водителя
  const handleTrackDriver = async (driver) => {
    try {
      // Отправляем запрос на сервер для получения геолокации водителя
      const response = await axios.get(`${API_URL}/driver_location/${driver.id}`);
      const { geolocation } = response.data;

      // Устанавливаем данные в состояние
      setDriverLocation(geolocation);
      setSelectedDriver(driver);
      setTrackDialogOpen(true);
    } catch (error) {
      console.error('Ошибка при получении геолокации водителя:', error);
      setSnackbarMessage('Die Geolocation des Fahrers konnte nicht abgerufen werden.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Определение действий для Speed Dial
  const actions = [
    { icon: <AddIcon />, name: 'Baustelle hinzufügen', action: handleDialogOpen },
    { icon: <PersonAddIcon />, name: 'Fahrer hinzufügen', action: handleAddDriverOpen },
    { icon: <PeopleIcon />, name: 'Fahrerverwaltung', action: handleManageDriversOpen },
    { icon: <DescriptionIcon />, name: 'Protokolle anzeigen', action: handleViewAllProtocols }, // Новая кнопка
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {/* Объединённый контейнер для строки поиска и вкладок */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 0,
            position: 'relative',
          }}
        >
          {/* Строка поиска слева */}
          <Box
            ref={searchContainerRef}
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
              transition: 'width 0.3s ease',
              width: isSearchOpen ? '500px' : '40px', // Ширина при открытом и закрытом состоянии
              borderRadius: '4px',

            }}
          >
            <TextField
              label="Suchen"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              autoFocus={isSearchOpen}
              inputRef={searchInputRef}
              sx={{
                flexGrow: 1,
                transition: 'width 0.3s ease, opacity 0.3s ease',
                width: isSearchOpen ? '100%' : '0px',
                opacity: isSearchOpen ? 1 : 0,
                borderRadius: 1,
              }}
              InputProps={{
                endAdornment: searchQuery ? (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Suche zurücksetzen"
                      onClick={handleClearSearch}
                      edge="end"
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
                style: {
                  paddingRight: '8px',
                },
              }}
            />
            <IconButton
              onClick={() => {
                if (isSearchOpen && !searchQuery) {
                  setIsSearchOpen(false);
                } else if (!isSearchOpen) {
                  setIsSearchOpen(true);
                }
              }}
              size="small"
              sx={{
                transition: 'transform 0.3s ease',
              }}
            >
              <SearchIcon />
            </IconButton>
          </Box>

          {/* Вкладки по центру */}
          <Box
            sx={{
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              backgroundColor: 'transparent',
              padding: '0 16px',
            }}
          >
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => setSelectedTab(newValue)}
              aria-label="Status Tabs"
              textColor="primary"
              indicatorColor="primary"
              centered
            >
              <Tab
                icon={<HourglassEmptyIcon />}
                iconPosition="start"
                label="Aktiv"
                value="active"
              />
              <Tab
                icon={<CheckCircleOutlineIcon />}
                iconPosition="start"
                label="Abgeschlossen"
                value="inactive"
              />
            </Tabs>
          </Box>
        </Box>

        <Box
          sx={{
            position: 'relative',
            minHeight: '100vh',
            backgroundColor: 'transparent',
          }}
        >
          {/* Таблица проектов */}
          <TableContainer component={MuiPaper} sx={{
            maxHeight: '70vh', // Устанавливаем максимальную высоту в 70% от высоты окна
            overflowY: 'auto', // Включаем вертикальную полосу прокрутки при переполнении
            mb: 4,
          }}>
            <Table stickyHeader size={dense ? 'small' : 'medium'}>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                  const isAsc = orderBy === property && order === 'asc';
                  setOrder(isAsc ? 'desc' : 'asc');
                  setOrderBy(property);
                }}
              />
              <TableBody>
                {visibleRows.length > 0 ? (
                  visibleRows.map((project, index) => (
                    <TableRow
                      key={project.id}
                      sx={{
                        '&:hover .action-icons': {
                          visibility: 'visible',
                          opacity: 1,
                        },
                        backgroundColor: index % 2 === 0 ? 'grey.900' : 'grey.800',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      {/* Новая ячейка статуса протоколов */}
                      <TableCell sx={{ minWidth: 20 }} align="center">
                        {protocolStatus[project.id] && protocolStatus[project.id].length > 0 ? (
                          <Tooltip title={protocolStatus[project.id].join(', ')} arrow>
                            <BlinkingIcon />
                          </Tooltip>
                        ) : null}
                      </TableCell>

                      {/* Остальные ячейки */}
                      <TableCell sx={{ color: 'white', minWidth: 50 }} align="right">
                        {project.id}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 150 }}>
                        {project.kostenstelle}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 200 }}>
                        <Tooltip
                          title={project.bauvorhaben.length > 20 ? project.bauvorhaben : ''}
                          placement="top"
                          arrow
                          disableHoverListener={project.bauvorhaben.length <= 20}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {project.bauvorhaben.length > 40
                              ? `${project.bauvorhaben.substring(0, 40)}...`
                              : project.bauvorhaben}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 100 }}>{project.ort}</TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 150 }}>
                        <Tooltip
                          title={project.strasse.length > 15 ? project.strasse : ''}
                          placement="top"
                          arrow
                          disableHoverListener={project.strasse.length <= 15}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {project.strasse.length > 30
                              ? `${project.strasse.substring(0, 30)}...`
                              : project.strasse}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 150 }}>
                        {getBauleiterName(project.bauleiter)}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 150 }}>
                        {project.protokoll}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 200 }}>
                        <Tooltip
                          title={project.beschreibung.length > 20 ? project.beschreibung : ''}
                          placement="top"
                          arrow
                          disableHoverListener={project.beschreibung.length <= 20}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {project.beschreibung.length > 50
                              ? `${project.beschreibung.substring(0, 50)}...`
                              : project.beschreibung}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 150 }}>
                        {(() => {
                          const names = getKontrollfahrerNames(project.kontrollfahrer);
                          const concatenatedNames = names.join(', ');
                          const maxLength = 30;

                          if (concatenatedNames.length > maxLength) {
                            return (
                              <Tooltip title={concatenatedNames} arrow>
                                <span>{`${names.length} Fahrer`}</span>
                              </Tooltip>
                            );
                          }

                          return concatenatedNames || 'Неизвестно';
                        })()}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 100 }}>
                        <Tooltip
                          title={project.haeufigkeit.length > 15 ? project.haeufigkeit : ''}
                          placement="top"
                          arrow
                          disableHoverListener={project.haeufigkeit.length <= 15}
                        >
                          <span
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {project.haeufigkeit.length > 30
                              ? `${project.haeufigkeit.substring(0, 30)}...`
                              : project.haeufigkeit}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 120 }}>
                        {project.sperrungVonFormatted}
                      </TableCell>
                      <TableCell sx={{ color: 'white', minWidth: 120 }}>
                        {project.sperrungBisFormatted}
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: 150 }}>
                        <Box
                          className="action-icons"
                          sx={{
                            display: 'flex',
                            gap: 1,
                            visibility: 'hidden',
                            opacity: 0,
                            transition: 'visibility 0.3s, opacity 0.3s',
                          }}
                        >
                          <Tooltip title="Bearbeiten">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleEdit(project)}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Foto ansehen">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleViewPhoto(project)}
                              size="small"
                            >
                              <PhotoIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Protokolle ansehen">
                            <IconButton
                              sx={{ color: 'grey.500' }}
                              onClick={() => handleViewProtocols(project)}
                              size="small"
                            >
                              <DescriptionIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={14} align="center">
                      Baustellen werden geladen...
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>


              <TableFooter
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  backgroundColor: 'background.paper',
                  zIndex: 1, // Это поможет избежать перекрытия футера строками таблицы при прокрутке
                }}>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={14}
                    count={filteredProjects.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Anzahl der Zeilen pro Seite',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>

          {/* Speed Dial */}
          <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1300 }}>
            <SpeedDial
              ariaLabel="SpeedDial для Projects"
              sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
                '& .MuiFab-primary': {
                  backgroundColor: 'red',
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                },
              }}
              icon={<SpeedDialIcon />}
              onClose={handleSpeedDialClose}
              onOpen={handleSpeedDialOpen}
              open={speedDialOpen}
              direction="up"
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.action}
                />
              ))}
            </SpeedDial>
          </Box>

          {/* Диалоговое окно для показа фото к стройке */}
          <Dialog
            open={photoDialogOpen}
            onClose={() => setPhotoDialogOpen(false)}
            fullWidth
            maxWidth="lg"
            aria-labelledby="photo-dialog-title"
          >
            <DialogTitle id="photo-dialog-title">
              Baustellenfotos {currentPhotoProject?.bauvorhaben}
            </DialogTitle>
            <DialogContent dividers>
              {/* Панель фильтрации */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mb: 2,
                  flexWrap: 'wrap',
                }}
              >
                {/* Фильтр по дате: от */}
                <DatePicker
                  label="Vom"
                  value={filterFromDate}
                  onChange={(newValue) => setFilterFromDate(newValue)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />

                {/* Фильтр по дате: до */}
                <DatePicker
                  label="Bis"
                  value={filterToDate}
                  onChange={(newValue) => setFilterToDate(newValue)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
                {/* Кнопка очистки фильтров */}
                <Tooltip title="Filter löschen">
                  <IconButton sx={{ color: 'grey.500' }} onClick={clearFilters}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {/* Остальная часть контента диалогового окна с фотографиями */}
              {photosLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                  <CircularProgress />
                </Box>
              ) : filteredPhotoUrls.length > 0 ? (
                <Grid container spacing={2}>
                  {filteredPhotoUrls.map(({ url }, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <Box
                        sx={{
                          width: '100%',
                          height: 200, // Фиксированная высота
                          position: 'relative',
                          overflow: 'hidden',
                          borderRadius: 1,
                        }}
                      >
                        <LazyLoadImage
                          src={url}
                          alt={`Foto ${index + 1}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Обеспечивает заполнение контейнера
                            borderRadius: 8,
                            cursor: 'pointer',
                            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = 'scale(1.05)';
                            e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.3)';
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = 'scale(1)';
                            e.currentTarget.style.boxShadow = 'none';
                          }}
                          onClick={() => {
                            setCurrentImageIndex(index);
                            setIsLightboxOpen(true);
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="body1">Es sind keine Fotos für diese Baustelle verfügbar.</Typography>
              )}

            </DialogContent>


            <DialogActions>
              <Button onClick={() => setPhotoDialogOpen(false)} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={protocolsDialogOpen}
            onClose={handleProtocolsDialogClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="protocols-dialog-title"
          >
            <DialogTitle id="protocols-dialog-title">Protokolle des Projekts {selectedProject?.bauvorhaben}</DialogTitle>
            <DialogContent dividers>
              <TableContainer component={MuiPaper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 30 }}>Status</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Kostenstelle</TableCell>
                      <TableCell>Ort</TableCell>
                      <TableCell>Strasse</TableCell>
                      <TableCell>Zeit</TableCell>
                      <TableCell>Kontrollfahrer</TableCell>
                      <TableCell align="center">Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {protocolsData.map((protocol) => (
                      <TableRow key={protocol.id}>
                        <TableCell>
                          {protocol.end_time ? (
                            <FiberManualRecordIcon style={{ color: 'green' }} />
                          ) : (
                            <BlinkingIcon />
                          )}
                        </TableCell>
                        <TableCell>{protocol.id}</TableCell>
                        <TableCell>{protocol.kostenstelle}</TableCell>
                        <TableCell>{protocol.ort}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={protocol.strasse.length > 15 ? protocol.strasse : ''}
                            placement="top"
                            arrow
                            disableHoverListener={protocol.strasse.length <= 15}
                          >
                            <span
                              style={{
                                display: 'block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {protocol.strasse.length > 30
                                ? `${protocol.strasse.substring(0, 30)}...`
                                : protocol.strasse}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{moment.parseZone(protocol.zeit).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{protocol.kontrollfahrer_name}</TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                            <Tooltip title="Kleines Protokoll herunterladen">
                              <IconButton
                                sx={{ color: protocol.end_time ? 'grey.500' : 'grey.900' }}
                                onClick={() => downloadSmallProtocol(protocol.id)}
                                size="small"
                                disabled={!protocol.end_time}
                              >
                                <DescriptionIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Großes Protokoll herunterladen">
                              <IconButton
                                sx={{ color: protocol.end_time ? 'grey.500' : 'grey.900' }}
                                onClick={() => downloadBigProtocol(protocol.id)}
                                size="small"
                                disabled={!protocol.end_time}
                              >
                                <AssignmentIcon />
                              </IconButton>
                            </Tooltip>
                            {!protocol.end_time && (
                              <Tooltip title="Protokoll schließen">
                                <IconButton
                                  onClick={() => handleCloseProtocolClick(protocol)}
                                  size="small"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>


                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleProtocolsDialogClose} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>


          {/* Dialog для добавления/редактирования проекта с Stepper */}
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {isEditing ? 'Baustelle bearbeiten' : 'Neue Baustelle hinzufügen'}
            </DialogTitle>
            <DialogContent dividers>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {index === 0 && (
                        <Box sx={{ mt: 2 }}>
                          <FormControl fullWidth margin="dense" size="small">
                            <InputLabel id="status-label">Status</InputLabel>
                            <Select
                              labelId="status-label"
                              id="status"
                              name="status"
                              value={newProject.status}
                              label="Status"
                              onChange={handleInputChange}
                            >
                              <MenuItem value="active">Aktiv</MenuItem>
                              <MenuItem value="inactive">Abgeschlosen</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            label="Kostenstelle"
                            name="kostenstelle"
                            value={newProject.kostenstelle}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            InputProps={{
                              readOnly: isEditing,
                            }}
                          />
                          <TextField
                            label="Bauvorhaben"
                            name="bauvorhaben"
                            value={newProject.bauvorhaben}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                          />
                          <FormControl fullWidth margin="dense" size="small">
                            <InputLabel id="bauleiter-label">Bauleiter</InputLabel>
                            <Select
                              labelId="bauleiter-label"
                              id="bauleiter"
                              name="bauleiter"
                              value={newProject.bauleiter}
                              label="Bauleiter"
                              onChange={(e) => {
                                const selectedBauleiterId = e.target.value;
                                const selectedBauleiter = bauleiterList.find(
                                  (b) => b.id === selectedBauleiterId,
                                );
                                if (selectedBauleiter) {
                                  setNewProject((prev) => ({
                                    ...prev,
                                    bauleiter: selectedBauleiter.id,
                                    telephone: selectedBauleiter.telephone || '',
                                    email: selectedBauleiter.email || '',
                                  }));
                                } else {
                                  setNewProject((prev) => ({
                                    ...prev,
                                    bauleiter: '',
                                    telephone: '',
                                    email: '',
                                  }));
                                }
                              }}
                            >
                              {bauleiterList.map((bauleiter) => (
                                <MenuItem key={bauleiter.id} value={bauleiter.id}>
                                  {bauleiter.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Telephone"
                            name="telephone"
                            value={newProject.telephone}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <TextField
                            label="E-Mail"
                            name="email"
                            type="email"
                            value={newProject.email}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                            margin="dense"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <FormControl fullWidth margin="dense" size="small">
                            <InputLabel id="protokoll-label">Versand Protokoll</InputLabel>
                            <Select
                              labelId="protokoll-label"
                              id="protokoll"
                              name="protokoll"
                              value={newProject.protokoll}
                              label="Protokoll"
                              onChange={handleInputChange}
                            >
                              <MenuItem value="Kleines Protokoll">Kleines Protokoll</MenuItem>
                              <MenuItem value="Großes Protokoll">Großes Protokoll</MenuItem>
                            </Select>
                          </FormControl>
                          <TextField
                            label="Beschreibung"
                            name="beschreibung"
                            value={newProject.beschreibung}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            multiline
                            rows={3}
                            size="small"
                          />
                          <Box sx={{ mt: 1 }}>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Weiter
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {index === 1 && (
                        <Box sx={{ mt: 2 }}>
                          <FormControl component="fieldset" variant="standard">
                            <Typography variant="subtitle1" gutterBottom>
                              Wählen Sie einen Kontrollfahrer aus:
                            </Typography>
                            <FormGroup>
                              {fahrerList.map((fahrer) => (
                                <FormControlLabel
                                  key={fahrer.id}
                                  control={
                                    <Checkbox
                                      checked={newProject.kontrollfahrer.includes(fahrer.id)}
                                      onChange={() => handleKontrollfahrerChange(fahrer.id)}
                                      name={fahrer.name}
                                      size="small"
                                    />
                                  }
                                  label={fahrer.name}
                                />
                              ))}
                            </FormGroup>
                          </FormControl>
                          <Box sx={{ mt: 1 }}>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Weiter
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleBack}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Zurück
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {index === 2 && (
                        <Box sx={{ mt: 2 }}>
                          <TextField
                            label="Ort"
                            name="ort"
                            value={newProject.ort}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                          />
                          <TextField
                            label="Strasse"
                            name="strasse"
                            value={newProject.strasse}
                            onChange={handleInputChange}
                            fullWidth
                            size="small"
                          />
                          <FormControl fullWidth margin="dense" size="small">
                            <InputLabel id="haeufigkeit-label">
                              Häufigkeit der Kontrollen
                            </InputLabel>
                            <Select
                              labelId="haeufigkeit-label"
                              id="haeufigkeit"
                              name="haeufigkeit"
                              value={newProject.haeufigkeit}
                              label="Häufigkeit der Kontrollen"
                              onChange={handleInputChange}
                            >
                              <MenuItem value="2x arbeitstäglich, 1x an arbeitsfreien Tagen">2x arbeitstäglich, 1x an arbeitsfreien Tagen</MenuItem>
                              <MenuItem value="2x arbeitstäglich,">2x arbeitstäglich</MenuItem>
                              <MenuItem value="1x an arbeitsfreien Tagen">1x an arbeitsfreien Tagen</MenuItem>
                              <MenuItem value="1x wöchentlich">1x wöchentlich</MenuItem>
                              <MenuItem value="täglich">täglich</MenuItem>
                              <MenuItem value="Sonstiges">Sonstiges</MenuItem>
                            </Select>
                          </FormControl>
                          <DatePicker
                            label="Sperrung vom"
                            value={newProject.sperrungVon}
                            onChange={(newValue) => {
                              setNewProject((prev) => ({ ...prev, sperrungVon: newValue }));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth margin="dense" size="small" />
                            )}
                          />
                          <DatePicker
                            label="Sperrung bis"
                            value={newProject.sperrungBis}
                            onChange={(newValue) => {
                              setNewProject((prev) => ({ ...prev, sperrungBis: newValue }));
                            }}
                            renderInput={(params) => (
                              <TextField {...params} fullWidth margin="dense" size="small" />
                            )}
                          />
                          <Box sx={{ mt: 2 }}>
                            <Button
                              variant="contained"
                              component="label"
                              startIcon={<UploadFileIcon />}
                              size="small"
                            >
                              VRAO hochladen
                              <input
                                type="file"
                                hidden
                                accept=".pdf,.doc,.docx"
                                onChange={handleVRAOUpload}
                              />
                            </Button>
                            {newProject.vrao ? (
                              <List dense>
                                <ListItem divider>
                                  <ListItemText primary={newProject.vrao.name} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={handleVRAODelete}
                                      size="small"
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            ) : newProject.vraoURL && newProject.vraoFileName ? (
                              <List dense>
                                <ListItem divider>
                                  <ListItemText
                                    primary={
                                      <a
                                        href={newProject.vraoURL}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textDecoration: 'none', color: 'inherit' }}
                                      >
                                        {newProject.vraoFileName}
                                      </a>
                                    }
                                  />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={handleVRAODelete}
                                      size="small"
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              </List>
                            ) : null}
                          </Box>

                          <Box sx={{ mt: 1 }}>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Weiter
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleBack}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Zurück
                            </Button>
                          </Box>
                        </Box>
                      )}
                      {index === 3 && (
                        <Box sx={{ mt: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Button
                              variant="contained"
                              component="label"
                              startIcon={<UploadFileIcon />}
                              size="small"
                            >
                              VZP hochladen
                              <input
                                type="file"
                                hidden
                                accept=".pdf"
                                multiple
                                onChange={handleVZPUpload}
                              />
                            </Button>
                          </Box>
                          {/* Отображение загруженных файлов VZP */}
                          <List dense>
                            {newProject.vzpURLs.map((fileObj, index) => (
                              <ListItem key={`vzp-url-${index}`} divider>
                                <ListItemText
                                  primary={
                                    <a
                                      href={fileObj.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ textDecoration: 'none', color: 'inherit' }}
                                    >
                                      {fileObj.name}
                                    </a>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleVZPURLDelete(index)}
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                            {newProject.vzp.map((fileObj, index) => (
                              <ListItem key={`vzp-file-${index}`} divider>
                                <ListItemText primary={fileObj.name} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleVZPDelete(index)}
                                    size="small"
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                          <Box sx={{ mt: 1 }}>
                            <Button
                              variant="contained"
                              onClick={handleSaveProject}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              {isEditing ? 'Änderungen speichern' : 'Speichern'}
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleBack}
                              size="small"
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Zurück
                            </Button>
                            {isEditing && (
                              <Button
                                variant="contained"
                                color="error"
                                onClick={handleDeleteProject}
                                size="small"
                                sx={{ mt: 1 }}
                              >
                                Baustelle löschen
                              </Button>
                            )}
                          </Box>
                        </Box>
                      )}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <MuiPaper square elevation={0} sx={{ p: 2 }}>
                  <Typography>Baustelle erfolgreich erstellt!</Typography>
                  <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }} size="small">
                    Weitere erstellen
                  </Button>
                </MuiPaper>
              )}
            </DialogContent>
          </Dialog>

          {/* Диалоговое окно подтверждения удаления проекта */}
          <Dialog
            open={deleteDialogOpen}
            onClose={cancelDeleteProject}
            aria-labelledby="delete-dialog-title"
          >
            <DialogTitle id="delete-dialog-title">Baustelle löschen</DialogTitle>
            <DialogContent>
              <Typography>
                Bitte geben Sie das Passwort ein, um "<strong>{newProject.bauvorhaben}</strong>" dauerhaft zu löschen.
              </Typography>
              <TextField
                label="Passwort"
                type="password"
                fullWidth
                value={deletePassword}
                onChange={(e) => setDeletePassword(e.target.value)}
                error={Boolean(deleteError)}
                helperText={deleteError}
                margin="dense"
                size="small"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelDeleteProject} color="primary">
                Abbrechen
              </Button>
              <Button onClick={confirmDeleteProject} color="error">
                Löschen
              </Button>
            </DialogActions>
          </Dialog>


          {/* Диалог для добавления водителя */}
          <Dialog
            open={driverDialogOpen}
            onClose={handleDriverDialogClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="driver-dialog-title"
          >
            <DialogTitle id="driver-dialog-title">Neuen Fahrer hinzufügen</DialogTitle>
            <DialogContent dividers>
              <Box sx={{ mt: 2 }}>
                <TextField
                  label="Name"
                  name="name"
                  value={newDriver.name}
                  onChange={handleDriverInputChange}
                  fullWidth
                  size="small"
                />
                <TextField
                  label="Passwort"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={newDriver.password}
                  onChange={handleDriverInputChange}
                  fullWidth
                  size="small"
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Passwort bestätigen"
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={newDriver.confirmPassword}
                  onChange={handleDriverInputChange}
                  fullWidth
                  size="small"
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          size="small"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Telefonnummer"
                  name="telephone"
                  value={newDriver.telephone}
                  onChange={handleDriverInputChange}
                  fullWidth
                  size="small"
                  margin="dense"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDriverDialogClose} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleSaveDriver} color="primary">
                Speichern
              </Button>
            </DialogActions>
          </Dialog>

          {/* Диалог для управления водителями */}
          <Dialog
            open={manageDriversDialogOpen}
            onClose={handleManageDriversClose}
            fullWidth
            maxWidth="md"
            aria-labelledby="manage-drivers-dialog-title"
          >
            <DialogTitle id="manage-drivers-dialog-title">Fahrerverwaltung</DialogTitle>
            <DialogContent dividers>
              <TableContainer component={MuiPaper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Telefonnummer</TableCell>
                      <TableCell align="center">Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fahrerList.map((driver) => (
                      <TableRow key={driver.id}>
                        <TableCell>{driver.name}</TableCell>
                        <TableCell>{driver.telephone}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="Bearbeiten"
                            onClick={() => handleEditDriver(driver)}
                            size="small"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="Löschen"
                            onClick={() => handleDeleteDriver(driver)}
                            size="small"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            aria-label="Standort verfolgen"
                            onClick={() => handleTrackDriver(driver)}
                            size="small"
                          >
                            <LocationOnIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleManageDriversClose} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Диалог для редактирования водителя */}
          <Dialog
            open={editDriverDialogOpen}
            onClose={handleEditDriverDialogClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="edit-driver-dialog-title"
          >
            <DialogTitle id="edit-driver-dialog-title">Fahrer bearbeiten</DialogTitle>
            <DialogContent dividers>
              {driverToEdit && (
                <Box sx={{ mt: 2 }}>
                  <TextField
                    label="Name"
                    name="name"
                    value={driverToEdit.name}
                    onChange={handleEditDriverInputChange}
                    fullWidth
                    size="small"
                  />
                  <TextField
                    label="Neues Passwort"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={driverToEdit.password || ''}
                    onChange={handleEditDriverInputChange}
                    fullWidth
                    size="small"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                            size="small"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Passwort bestätigen"
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={driverToEdit.confirmPassword || ''}
                    onChange={handleEditDriverInputChange}
                    fullWidth
                    size="small"
                    margin="dense"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                            size="small"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Telefonnummer"
                    name="telephone"
                    value={driverToEdit.telephone}
                    onChange={handleEditDriverInputChange}
                    fullWidth
                    size="small"
                    margin="dense"
                  />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditDriverDialogClose} color="primary">
                Отмена
              </Button>
              <Button onClick={handleSaveEditedDriver} color="primary">
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>

          {/* Диалоговое окно со всеми протоколами */}
          <Dialog
            open={allProtocolsDialogOpen}
            onClose={() => setAllProtocolsDialogOpen(false)}
            fullWidth
            maxWidth="md"
            aria-labelledby="all-protocols-dialog-title"
          >
            <DialogTitle id="all-protocols-dialog-title">Alle Protokolle</DialogTitle>
            <DialogContent dividers>
              {/* Панель фильтров */}
              <Box sx={{ display: 'flex', gap: 2, mb: 0, flexWrap: 'wrap' }}>
                {/* Фильтр по дате: от */}
                <DatePicker
                  label="Vom"
                  value={protocolsFilterFromDate}
                  onChange={(newValue) => setProtocolsFilterFromDate(newValue)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
                {/* Фильтр по дате: до */}
                <DatePicker
                  label="Bis"
                  value={protocolsFilterToDate}
                  onChange={(newValue) => setProtocolsFilterToDate(newValue)}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
                {/* Поле поиска */}
                <TextField
                  label="Suchen"
                  value={protocolsSearchQuery}
                  onChange={(e) => setProtocolsSearchQuery(e.target.value)}

                />
                {/* Кнопка очистки фильтров */}
                <Tooltip title="Filter löschen">
                  <IconButton sx={{ color: 'grey.500' }} onClick={clearProtocolsFilters}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              {/* Таблица протоколов */}
              <TableContainer component={MuiPaper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ width: 30 }}>Status</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Kostenstelle</TableCell>
                      <TableCell>Ort</TableCell>
                      <TableCell>Strasse</TableCell>
                      <TableCell>Zeit</TableCell>
                      <TableCell>Kontrollfahrer</TableCell>
                      <TableCell align="center">Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProtocolsData.map((protocol) => (
                      <TableRow key={protocol.id}>
                        <TableCell>
                          {protocol.end_time ? (
                            <FiberManualRecordIcon style={{ color: 'green' }} />
                          ) : (
                            <BlinkingIcon />
                          )}
                        </TableCell>
                        <TableCell>{protocol.id}</TableCell>
                        <TableCell>{protocol.kostenstelle}</TableCell>
                        <TableCell>{protocol.ort}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={protocol.strasse.length > 15 ? protocol.strasse : ''}
                            placement="top"
                            arrow
                            disableHoverListener={protocol.strasse.length <= 15}
                          >
                            <span
                              style={{
                                display: 'block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {protocol.strasse.length > 30
                                ? `${protocol.strasse.substring(0, 30)}...`
                                : protocol.strasse}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>{moment.parseZone(protocol.zeit).format('DD.MM.YYYY HH:mm')}</TableCell>
                        <TableCell>{protocol.kontrollfahrer_name}</TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 0 }}>
                            <Tooltip title="Kleines Protokoll herunterladen">
                              <IconButton
                                sx={{ color: protocol.end_time ? 'grey.500' : 'grey.900' }}
                                onClick={() => downloadSmallProtocol(protocol.id)}
                                size="small"
                                disabled={!protocol.end_time}
                              >
                                <DescriptionIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Großes Protokoll herunterladen">
                              <IconButton
                                sx={{ color: protocol.end_time ? 'grey.500' : 'grey.900' }}
                                onClick={() => downloadBigProtocol(protocol.id)}
                                size="small"
                                disabled={!protocol.end_time}
                              >
                                <AssignmentIcon />
                              </IconButton>
                            </Tooltip>
                            {!protocol.end_time && (
                              <Tooltip title="Protokoll schließen">
                                <IconButton
                                  onClick={() => handleCloseProtocolClick(protocol)}
                                  size="small"
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </TableCell>

                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setAllProtocolsDialogOpen(false)} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* диалоговое окно подтверждения закрытия протокола */}
          <Dialog
            open={closeProtocolDialogOpen}
            onClose={handleCancelCloseProtocol}
            aria-labelledby="close-protocol-dialog-title"
          >
            <DialogTitle id="close-protocol-dialog-title">Protokoll schließen</DialogTitle>
            <DialogContent>
              <Typography>
                Sind Sie sicher, dass Sie das Protokoll für{' '}
                <strong>{protocolToClose?.kostenstelle}</strong> schließen möchten?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelCloseProtocol} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleConfirmCloseProtocol} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>

          {/* диалоговое окно для уведомления о существующем проекте */}
          <Dialog
            open={existingProjectDialogOpen}
            onClose={() => setExistingProjectDialogOpen(false)}
            aria-labelledby="existing-project-dialog-title"
          >
            <DialogTitle id="existing-project-dialog-title">Baustelle existiert bereits</DialogTitle>
            <DialogContent>
              <Typography>
                Eine Baustelle mit der Kostenstelle "{newProject.kostenstelle}" existiert bereits. Möchten Sie sie bearbeiten?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  // Если пользователь нажимает "Nein", просто закрываем диалог и позволяем продолжить
                  setExistingProjectDialogOpen(false);
                }}
                color="primary"
              >
                Nein
              </Button>
              <Button
                onClick={() => {
                  // Если пользователь нажимает "Ja", закрываем текущий диалог и открываем диалог редактирования
                  setExistingProjectDialogOpen(false);
                  handleDialogClose(); // Закрываем диалог добавления
                  handleEdit(existingProject); // Открываем диалог редактирования
                }}
                color="primary"
              >
                Ja
              </Button>
            </DialogActions>
          </Dialog>

          {/* Диалоговое окно подтверждения удаления водителя */}
          <Dialog
            open={deleteDriverDialogOpen}
            onClose={handleCancelDeleteDriver}
            aria-labelledby="delete-driver-dialog-title"
          >
            <DialogTitle id="delete-driver-dialog-title">Fahrer löschen</DialogTitle>
            <DialogContent>
              <Typography>
                Sind Sie sicher, dass Sie{' '}
                <strong>{driverToDelete?.name}</strong> dauerhaft löschen möchten?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDeleteDriver} color="primary">
                Abbrechen
              </Button>
              <Button onClick={handleConfirmDeleteDriver} color="error">
                Löschen
              </Button>
            </DialogActions>
          </Dialog>

          {/* Диалоговое окно для отслеживания водителя водителя */}
          <Dialog
            open={trackDialogOpen}
            onClose={() => setTrackDialogOpen(false)}
            fullWidth
            maxWidth="sm"
            aria-labelledby="track-dialog-title"
          >
            <DialogTitle id="track-dialog-title">
              Standort des Fahrers {selectedDriver?.name}
            </DialogTitle>
            <DialogContent dividers>
              {driverLocation ? (
                <MapContainer
                  center={[driverLocation.latitude, driverLocation.longitude]}
                  zoom={13}
                  style={{ height: '400px', width: '100%' }}
                >
                  <TileLayer
                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <Marker position={[driverLocation.latitude, driverLocation.longitude]}>
                    <Popup>
                      {selectedDriver?.name} befindet sich hier.
                    </Popup>
                  </Marker>
                </MapContainer>
              ) : (
                <Typography>Keine Geolocation verfügbar.</Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setTrackDialogOpen(false)} color="primary">
                Schließen
              </Button>
            </DialogActions>
          </Dialog>


          {/* Backdrop для загрузки */}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
            open={isLoading}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress color="inherit" />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Bitte warten Sie, es wird geladen...
              </Typography>
            </Box>
          </Backdrop>

          {/* Snackbar для уведомлений */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {isLightboxOpen && (
            <Lightbox
              open={isLightboxOpen}
              close={() => setIsLightboxOpen(false)}
              slides={filteredPhotoUrls.map((photo) => ({ src: photo.url }))}
              index={currentImageIndex}
              onMovePrevRequest={() =>
                setCurrentImageIndex((currentImageIndex + filteredPhotoUrls.length - 1) % filteredPhotoUrls.length)
              }
              onMoveNextRequest={() =>
                setCurrentImageIndex((currentImageIndex + 1) % filteredPhotoUrls.length)
              }
            />
          )}
        </Box>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

// Определение шагов для Stepper
const steps = [
  {
    label: 'Hauptinformationen',
    content: 'Bitte füllen Sie die Hauptinformationen zur Baustelle aus.',
  },
  {
    label: 'Kontrollfahrer',
    content: 'Wählen Sie die Mitarbeiter aus, die diese Baustellen in der mobilen Anwendung sehen können.',
  },
  {
    label: 'Zusätzliche Informationen',
    content: 'Bitte füllen Sie die zusätzlichen Daten zum Projekt aus.',
  },
  {
    label: 'VZP',
    content: 'Laden Sie die PDF-Dateien VZP hoch.',
  },
];

export default Projects;
