// protocolGenerator.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

// Импорт Firebase и необходимых функций
import { storage } from '../firebase'; // Убедитесь, что путь корректен относительно расположения файлов
import { ref, getMetadata } from 'firebase/storage';

// Импортируем логотип
import logoImage from '../images/app_ico.png'; // Убедитесь, что путь корректен

// Функция для загрузки изображения в формате Data URL
const fetchImageAsDataURL = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Ошибка при загрузке изображения: ${response.statusText}`);
  }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

// Функция для извлечения пути из URL Firebase Storage
function getStoragePathFromUrl(url) {
  const match = url.match(/\/o\/(.*?)\?/);
  if (match && match[1]) {
    const path = decodeURIComponent(match[1]);
    return path;
  }
  return null;
}

// Функция для добавления нижнего колонтитула
function addFooter(doc, logoImage, currentPage, totalPages) {
  const pageSize = doc.internal.pageSize;
  const pageWidth = pageSize.getWidth();
  const pageHeight = pageSize.getHeight();
  const footerMargin = 5;

  // Добавляем логотип слева
  const footerLogoWidth = 15; // мм
  const footerLogoHeight = 15; // мм
  const logoX = footerMargin;
  const logoY = pageHeight - footerLogoHeight - footerMargin;

  doc.addImage(logoImage, 'PNG', logoX, logoY, footerLogoWidth, footerLogoHeight);

  // Добавляем номер страницы справа
  doc.setFontSize(10);
  const pageNumberText = `Seite ${currentPage} von ${totalPages}`;
  const textWidth = doc.getTextWidth(pageNumberText);
  const textX = pageWidth - textWidth - footerMargin;
  const textY = pageHeight - footerMargin;

  doc.text(pageNumberText, textX, textY);
}

// Функция для генерации малого протокола
export const generateSmallProtocolPDF = async (protocol) => {
  const doc = new jsPDF();

  // Устанавливаем отступы
  const marginLeft = 5;
  const marginTop = 5;

  // Размеры логотипа
  const imgWidth = 15;
  const imgHeight = 15;

  // Добавляем логотип
  doc.addImage(logoImage, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);

  // Позиция текста справа от логотипа
  const textLeft = marginLeft + imgWidth + 5;

  // Текст в двух строках
  const firstLineText = 'TrafficTools - Überwachung';
  const secondLineText = 'VVO GmbH & Co.KG - Ernst-Abbe-Straße 26 - 89079 Ulm';

  // Первая строка текста
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text(firstLineText, textLeft, marginTop + 5);

  // Вторая строка текста
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'normal');
  doc.text(secondLineText, textLeft, marginTop + 15);

  // Текущая позиция по Y
  let currentY = Math.max(marginTop + imgHeight, marginTop + 5) + 5;

  // Разделительная линия
  doc.setLineWidth(0.5);
  doc.line(marginLeft, currentY, doc.internal.pageSize.getWidth() - marginLeft, currentY);

  currentY += 10;

  // Заголовок документа
  doc.setFontSize(20);
  doc.setFont('Helvetica', 'bold');
  doc.text(
    `Kontrollbericht ${moment(protocol.zeit).format('DD.MM.YYYY')}`,
    doc.internal.pageSize.getWidth() / 2,
    currentY,
    { align: 'center' }
  );

  currentY += 10;

  // Подзаголовок
  doc.setFontSize(15);
  doc.setFont('Helvetica', 'bold');
  doc.text(
    `VVO GmbH & Co.KG - Ernst-Abbe-Straße 26 - 89079 Ulm`,
    doc.internal.pageSize.getWidth() / 2,
    currentY,
    { align: 'center' }
  );

  currentY += 15;

  // Первая таблица: Основная информация
  const tableRows1 = [
    ['Auftragsnummer:', protocol.id.toString()],
    ['Kostenstelle:', protocol.kostenstelle],
    ['Ort:', protocol.ort],
    ['Strasse:', protocol.strasse],
    ['Sperrung vom:', protocol.sperrungvon ? moment(protocol.sperrungvon).format('DD.MM.YYYY') : ''],
    ['Sperrung bis:', protocol.sperrungbis ? moment(protocol.sperrungbis).format('DD.MM.YYYY') : ''],
    ['Beschreibung:', protocol.beschreibung || ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows1,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: doc.internal.pageSize.getWidth() - 2 * marginLeft - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // Вторая таблица: Kontrollfahrer и время
  const tableRows2 = [
    ['Kontrollfahrer:', protocol.kontrollfahrer_name],
    ['Startzeit:', protocol.startzeit ? moment.parseZone(protocol.startzeit).format('DD.MM.YYYY HH:mm') : ''],
    ['Endzeit:', protocol.endzeit ? moment.parseZone(protocol.endzeit).format('DD.MM.YYYY HH:mm') : ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows2,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: doc.internal.pageSize.getWidth() - 2 * marginLeft - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft },
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // Третья таблица: Fahrbahnzustand и Wetter
  const tableRows3 = [
    ['Fahrbahnzustand:', protocol.fahrbahn || ''],
    ['Wetter:', protocol.wetter || ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows3,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: doc.internal.pageSize.getWidth() - 2 * marginLeft - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft },
  });

  currentY = doc.lastAutoTable.finalY + 20;

  // Подпись Kontrollfahrer
  doc.setFontSize(12);
  doc.setFont('Helvetica', 'normal');
  doc.text(`Kontrollfahrer: ${protocol.kontrollfahrer_name}`, marginLeft, currentY);

  // Загрузка изображения подписи из URL
  if (protocol.signature_url) {
    try {
      // Загружаем изображение подписи в формате Data URL
      const signatureDataURL = await fetchImageAsDataURL(protocol.signature_url);

      // Размеры изображения подписи
      const signatureWidth = 60; // мм
      const signatureHeight = 30; // мм

      currentY += 5; // Отступ перед подписью

      // Добавляем изображение подписи
      doc.addImage(signatureDataURL, 'PNG', marginLeft, currentY, signatureWidth, signatureHeight);

      currentY += signatureHeight + 5;
    } catch (error) {
      console.error('Ошибка при загрузке изображения подписи:', error);

      // Если изображение подписи не удалось загрузить, добавляем линию для подписи
      currentY += 10;
      doc.setLineWidth(0.5);
      doc.line(marginLeft, currentY, marginLeft + 60, currentY); // Длина линии 60 мм

      currentY += 5;
    }
  } else {
    // Если signature_url отсутствует, добавляем линию для подписи
    currentY += 10;
    doc.setLineWidth(0.5);
    doc.line(marginLeft, currentY, marginLeft + 60, currentY); // Длина линии 60 мм

    currentY += 5;
  }

  // Добавляем нижний колонтитул на каждую страницу
  const totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    addFooter(doc, logoImage, i, totalPages);
  }

  // Сохраняем PDF
  doc.save(`Kontrollbericht_${protocol.kostenstelle}-${protocol.ort},${protocol.strasse}.pdf`);
};

// Функция для генерации большого протокола
export const generateBigProtocolPDF = async (protocol) => {
  const doc = new jsPDF();

  // Размеры страницы и отступы
  const pageWidth = doc.internal.pageSize.getWidth();
  const marginLeft = 5;
  const marginRight = 5;
  const marginTop = 5;
  const marginBottom = 30; // Нижний отступ для колонтитула

  // Доступная ширина контента
  const contentWidth = pageWidth - marginLeft - marginRight;

  // Размеры логотипа
  const imgWidth = 15;
  const imgHeight = 15;

  // Добавляем логотип
  doc.addImage(logoImage, 'PNG', marginLeft, marginTop, imgWidth, imgHeight);

  // Позиция текста
  const textLeft = marginLeft + imgWidth + 5;

  // Заголовки
  const firstLineText = 'TrafficTools - Überwachung';
  const secondLineText = 'VVO GmbH & Co.KG - Ernst-Abbe-Straße 26 - 89079 Ulm';

  // Добавляем заголовки
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text(firstLineText, textLeft, marginTop + 5);
  doc.setFontSize(14);
  doc.setFont('Helvetica', 'normal');
  doc.text(secondLineText, textLeft, marginTop + 15);

  let currentY = Math.max(marginTop + imgHeight, marginTop + 5) + 5;

  // Разделительная линия
  doc.setLineWidth(0.5);
  doc.line(marginLeft, currentY, pageWidth - marginRight, currentY);
  currentY += 10;

  // Заголовок документа
  doc.setFontSize(20);
  doc.setFont('Helvetica', 'bold');
  doc.text(
    `Kontrollbericht ${moment(protocol.zeit).format('DD.MM.YYYY')}`,
    pageWidth / 2,
    currentY,
    { align: 'center' }
  );
  currentY += 10;

  // Подзаголовок
  doc.setFontSize(15);
  doc.setFont('Helvetica', 'bold');
  doc.text(
    'VVO GmbH & Co.KG - Ernst-Abbe-Straße 26 - 89079 Ulm',
    pageWidth / 2,
    currentY,
    { align: 'center' }
  );
  currentY += 15;

  // Первая таблица: Основная информация
  const tableRows1 = [
    ['Auftragsnummer:', protocol.id.toString()],
    ['Kostenstelle:', protocol.kostenstelle],
    ['Ort:', protocol.ort],
    ['Strasse:', protocol.strasse],
    ['Sperrung vom:', protocol.sperrungvon ? moment(protocol.sperrungvon).format('DD.MM.YYYY') : ''],
    ['Sperrung bis:', protocol.sperrungbis ? moment(protocol.sperrungbis).format('DD.MM.YYYY') : ''],
    ['Beschreibung:', protocol.beschreibung || ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows1,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: contentWidth - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft, right: marginRight },
    pageBreak: 'auto',
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // Вторая таблица: Kontrollfahrer и время
  const tableRows2 = [
    ['Kontrollfahrer:', protocol.kontrollfahrer_name],
    ['Startzeit:', protocol.startzeit ? moment.parseZone(protocol.startzeit).format('DD.MM.YYYY HH:mm') : ''],
    ['Endzeit:', protocol.endzeit ? moment.parseZone(protocol.endzeit).format('DD.MM.YYYY HH:mm') : ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows2,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: 0,
      fontStyle: 'bold',
      fontSize: 12,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: contentWidth - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft, right: marginRight },
    pageBreak: 'auto',
  });

  currentY = doc.lastAutoTable.finalY + 10;

  // Третья таблица: Fahrbahnzustand и Wetter
  const tableRows3 = [
    ['Fahrbahnzustand:', protocol.fahrbahn || ''],
    ['Wetter:', protocol.wetter || ''],
  ];

  doc.autoTable({
    startY: currentY,
    body: tableRows3,
    theme: 'grid',
    styles: {
      cellPadding: 2,
      fontSize: 10,
      lineWidth: 0.5,
    },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: 0,
      fontStyle: 'bold',
      fontSize: 12,
    },
    columnStyles: {
      0: { cellWidth: 50, fontStyle: 'bold' },
      1: {
        cellWidth: contentWidth - 50,
        fontStyle: 'normal',
      },
    },
    margin: { left: marginLeft, right: marginRight },
    pageBreak: 'auto',
  });

  currentY = doc.lastAutoTable.finalY + 20;

  // Подпись Kontrollfahrer
  doc.setFontSize(12);
  doc.setFont('Helvetica', 'normal');
  doc.text(`Kontrollfahrer: ${protocol.kontrollfahrer_name}`, marginLeft, currentY);

  // Загрузка изображения подписи из URL
  if (protocol.signature_url) {
    try {
      const signatureDataURL = await fetchImageAsDataURL(protocol.signature_url);
      const signatureWidth = 60;
      const signatureHeight = 30;

      currentY += 5;
      doc.addImage(signatureDataURL, 'PNG', marginLeft, currentY, signatureWidth, signatureHeight);

      currentY += signatureHeight + 5;
    } catch (error) {
      console.error('Ошибка при загрузке изображения подписи:', error);
      currentY += 10;
      doc.setLineWidth(0.5);
      doc.line(marginLeft, currentY, marginLeft + 60, currentY);
      currentY += 5;
    }
  } else {
    currentY += 10;
    doc.setLineWidth(0.5);
    doc.line(marginLeft, currentY, marginLeft + 60, currentY);
    currentY += 5;
  }

  // Добавляем новую страницу для дополнительных данных
  doc.addPage();

  currentY = marginTop + 10;

  // Заголовок для второй страницы
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.text('Zusätzliche Informationen', pageWidth / 2, currentY, { align: 'center' });

  currentY += 10;

  // Определяем желаемый порядок категорий
  const desiredOrder = ['Verkehrszeichen', 'Verkehrseinrichtungen', 'LSA'];

  // Проверяем наличие данных в comment_json
  if (protocol.comment_json && Object.keys(protocol.comment_json).length > 0) {
    for (const category of desiredOrder) {
      const items = protocol.comment_json[category];
      if (!items) continue; // Если категория отсутствует, пропускаем

      // Проверяем, является ли текущая категория "LSA"
      if (category === 'LSA') {
        // Добавляем новую страницу перед "LSA"
        doc.addPage();
        currentY = marginTop + 10; // Сброс текущей позиции Y на верхний отступ новой страницы
      }

      // Создаём строки для таблицы, включая строку заголовка категории
      const tableRows = [
        [category, ''], // Строка заголовка категории
        ...items.map((item) => [
          item.type || '',
          item.komment || '———',
        ]),
      ];

      // Создаём таблицу с использованием autoTable
      doc.autoTable({
        startY: currentY,
        body: tableRows,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 10,
          lineWidth: 0.5,
        },
        columnStyles: {
          0: { cellWidth: 50 },
          1: { cellWidth: contentWidth - 50 },
        },
        margin: { left: marginLeft, right: marginRight, bottom: marginBottom },
        pageBreak: 'auto',
        didParseCell: (data) => {
          // Если это первая строка (заголовок категории), установим colSpan и выравнивание
          if (data.section === 'body' && data.row.index === 0) {
            data.cell.colSpan = 2;
            data.cell.styles.halign = 'center';
            data.cell.styles.fontStyle = 'bold';
          }
        },
      });

      // Обновляем текущую позицию Y после таблицы
      currentY = doc.lastAutoTable.finalY + 10;
    }
  } else {
    doc.setFontSize(12);
    doc.setFont('Helvetica', 'normal');
    doc.text('Keine zusätzlichen Informationen verfügbar.', marginLeft, currentY);
    currentY += 10;
  }

  // Проверяем наличие фотографий
  if (protocol.photo_urls && protocol.photo_urls.length > 0) {
    // Добавляем третью страницу для фотографий
    doc.addPage();

    // Устанавливаем количество строк на страницу
    const ROWS_PER_PAGE = 3; // Вы можете изменить это значение по своему усмотрению

    const MAX_IMAGE_WIDTH = 70; // Настройте ширину фото
    const MAX_IMAGE_HEIGHT = 70; // Настройте высоту фото

    const photoDataArray = [];

    for (const photo of protocol.photo_urls) {
      const { url, name = 'Unbekannt', notiz = '' } = photo;

      try {
        const storagePath = getStoragePathFromUrl(url);

        let zeit = 'Keine Daten';
        let koordinate = 'Keine Daten';

        if (storagePath) {
          const storageRef = ref(storage, storagePath);
          const metadata = await getMetadata(storageRef);

          const { customMetadata } = metadata;
          zeit = customMetadata?.creation_time || 'Keine Daten';

          if (customMetadata?.latitude && customMetadata?.longitude) {
            koordinate = `${parseFloat(customMetadata.latitude).toFixed(6)}, ${parseFloat(customMetadata.longitude).toFixed(6)}`;
          }
        }

        // Загружаем изображение в формате Data URL
        const imgDataUrl = await fetchImageAsDataURL(url);

        // Подготавливаем данные фотографии
        photoDataArray.push({
          datei: name,
          foto: '',
          notiz: notiz,
          zeit: zeit,
          koordinate: koordinate,
          imgDataUrl: imgDataUrl,
        });
      } catch (error) {
        console.error('Error processing photo:', error);
        photoDataArray.push({
          datei: name,
          foto: '',
          notiz: '',
          zeit: 'Keine Daten',
          koordinate: 'Keine Daten',
          imgDataUrl: null,
        });
      }
    }

    // Функция для разделения массива на части
    function chunkArray(array, chunkSize) {
      const results = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        results.push(array.slice(i, i + chunkSize));
      }
      return results;
    }

    // Разделяем массив фотографий на части
    const photoChunks = chunkArray(photoDataArray, ROWS_PER_PAGE);

    // Задаем ширины столбцов
    const columnWidths = {
      datei: 30,
      foto: MAX_IMAGE_WIDTH + 1,
      notiz: 30,
      zeit: 35,
      koordinate: 35,
    };

    // Вычисляем суммарную ширину столбцов
    const totalColumnWidth = Object.values(columnWidths).reduce((a, b) => a + b, 0);

    // Если суммарная ширина превышает доступную, масштабируем столбцы
    if (totalColumnWidth > contentWidth) {
      const scale = contentWidth / totalColumnWidth;
      for (const key in columnWidths) {
        columnWidths[key] *= scale;
      }
    }

    const tableColumns = [
      { header: 'Datei', dataKey: 'datei' },
      { header: 'Foto', dataKey: 'foto' },
      { header: 'Notiz', dataKey: 'notiz' },
      { header: 'Zeit', dataKey: 'zeit' },
      { header: 'Koordinate', dataKey: 'koordinate' },
    ];

    // Отрисовываем каждую часть на отдельной странице
    for (let i = 0; i < photoChunks.length; i++) {
      const chunk = photoChunks[i];

      if (i > 0) {
        doc.addPage();
      }

      let currentY = marginTop + 5;

      // Добавляем заголовок на каждую страницу
      doc.setFontSize(16);
      doc.setFont('Helvetica', 'bold');
      doc.text(
        i === 0 ? 'Fotos' : 'Fotos',
        pageWidth / 2,
        currentY,
        { align: 'center' }
      );
      currentY += 10;

      doc.autoTable({
        startY: currentY,
        columns: tableColumns,
        body: chunk,
        theme: 'grid',
        styles: {
          cellPadding: 2,
          fontSize: 10,
          lineWidth: 0.5,
          valign: 'middle',
          halign: 'center',
        },
        bodyStyles: {
          minCellHeight: MAX_IMAGE_HEIGHT + 1,
        },
        headStyles: {
          fillColor: [200, 200, 200],
          textColor: 0,
          fontStyle: 'bold',
          fontSize: 12,
        },
        columnStyles: {
          datei: { cellWidth: columnWidths.datei, overflow: 'linebreak' },
          foto: { cellWidth: columnWidths.foto },
          notiz: { cellWidth: columnWidths.notiz },
          zeit: { cellWidth: columnWidths.zeit },
          koordinate: { cellWidth: columnWidths.koordinate },
        },
        margin: { left: marginLeft, right: marginRight, bottom: marginBottom },
        didDrawCell: (data) => {
          if (data.column.dataKey === 'foto' && data.cell.section === 'body') {
            const imgDataUrl = data.row.raw.imgDataUrl;
            if (imgDataUrl) {
              const cellWidth = data.cell.width;
              const cellHeight = data.cell.height;

              const imgProps = doc.getImageProperties(imgDataUrl);
              const imgWidth = imgProps.width;
              const imgHeight = imgProps.height;

              const widthRatio = MAX_IMAGE_WIDTH / imgWidth;
              const heightRatio = MAX_IMAGE_HEIGHT / imgHeight;
              const ratio = Math.min(widthRatio, heightRatio, 1);

              const imgDrawWidth = imgWidth * ratio;
              const imgDrawHeight = imgHeight * ratio;

              const x = data.cell.x + (cellWidth - imgDrawWidth) / 2;
              const y = data.cell.y + (cellHeight - imgDrawHeight) / 2;

              doc.addImage(imgDataUrl, x, y, imgDrawWidth, imgDrawHeight);
            }

            data.cell.text = '';
          }
        },
      });
    }
  } else {
    // Если фотографий нет, добавляем текст на текущей странице
    doc.setFontSize(12);
    doc.setFont('Helvetica', 'normal');
    doc.text('Keine Fotos verfügbar.', marginLeft, currentY);
    currentY += 10;
  }

  // Добавляем нижние колонтитулы на каждую страницу
  const totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    addFooter(doc, logoImage, i, totalPages);
  }

  // Сохраняем PDF
  doc.save(
    `Großer_Kontrollbericht_${protocol.kostenstelle}-${protocol.ort},${protocol.strasse}.pdf`
  );
};