// firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCGMWBZQ6aviSwDGpWAxE-8LtNi2xAEnEo",
  authDomain: "vigilant-mix-403621.firebaseapp.com",
  databaseURL: "https://vigilant-mix-403621-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "vigilant-mix-403621",
  storageBucket: "vigilant-mix-403621.appspot.com",
  messagingSenderId: "69698210415",
  appId: "1:69698210415:web:70fce6b44bb364b82f9b9a",
  measurementId: "G-K599PSFQ1M"
};
// Инициализация Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };