import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import SignInSide from './components/SignInSide';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
const API_URL = "https://us-central1-vigilant-mix-403621.cloudfunctions.net/api"

const root = ReactDOM.createRoot(document.getElementById('root'));

const Main = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.post(`${API_URL}/check-token`, { token });
          setIsAuthenticated(true);
        } catch (error) {
          setIsAuthenticated(false);
        }
      }
      setIsChecking(false);
    };
    checkToken();
  }, []);

  if (isChecking) {
    return (
      <Box sx={{ color: '#FF0000', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress color="inherit" />
      </Box>
    );
  }

  return <App isAuthenticated={isAuthenticated} />;
};

root.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>
);

reportWebVitals();
