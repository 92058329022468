import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { styled, useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import ChevronLeftIcon from '@mui/icons-material/ChevronRight';
import ChevronRightIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import BuildIcon from '@mui/icons-material/Build';
import Home from './components/Home';
import Projects from './components/Projects';
import Employees from './components/Employees';
import Equipment from './components/Equipment';
import './App.css';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import ConstructionIcon from '@mui/icons-material/Construction';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import SignInSide from './components/SignInSide';

const dialogTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b0b0b0',
    },
  },
  typography: {
    fontSize: 14,
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 12px',
          fontSize: '0.875rem',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
  },
});

const drawerWidth = 240;
const drawerBleeding = 56;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#333333',
  color: '#ffffff',
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: '#222222',
  color: '#ffffff',
  position: 'fixed',
  zIndex: theme.zIndex.drawer + 1,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);



const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

function Main({ onLogout }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const [swipeableDrawerOpen, setSwipeableDrawerOpen] = useState(false);

  // Новые состояния для диалогового окна и выбранной ссылки
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSheetLink, setSelectedSheetLink] = useState('');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    handleMenuClose();
    onLogout();
  };

  // Измененный обработчик для открытия диалогового окна
  const toggleSwipeableDrawer = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Обработчик выбора Google Sheets
  const handleSheetSelect = (link) => {
    setSelectedSheetLink(link);
    setDialogOpen(false);
    setSwipeableDrawerOpen(true);
  };

  const menuItems = [
    { text: 'Baustellen', path: '/', icon: <ConstructionIcon /> },
    { text: 'Uberwachung', path: '/projects', icon: <VideoCameraFrontIcon /> },
    { text: 'Employees', path: '/employees', icon: <PeopleIcon /> },
    { text: 'Equipment', path: '/equipment', icon: <BuildIcon /> }
  ];

  // Ссылки на Google Sheets
  const sheetLinks = {
    'Arbeitsplanung Ulm': 'https://docs.google.com/spreadsheets/d/1J_2BAPZo3GVMVVIy3chz0ba9qwwbm0HkeIUzi_cWZew/edit?usp=sharing',
    'Arbeitsplanung Augsburg': 'https://docs.google.com/spreadsheets/d/18_QBXY0wLANxhPcEkN5Hd7rR5RrdZU88BxezZzEFO6s/edit?usp=sharing',
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: '#222222' }}>
        <Toolbar>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
          <IconButton onClick={toggleSwipeableDrawer} sx={{ color: '#ffffff', mr: 2 }}>
            <EditCalendarIcon />
          </IconButton>
          <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
            <Avatar alt="App Avatar" src="/images/app_ico.png" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: '45px' }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open ? (
            <IconButton onClick={handleDrawerClose} sx={{ color: '#ffffff' }}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen} sx={{ color: '#ffffff' }}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={Link}
                to={item.path}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  '&:hover': {
                    backgroundColor: 'darkred',
                  },
                  '&.Mui-selected': {
                    backgroundColor: '#bf0d00',
                    '&:hover': {
                      backgroundColor: 'darkred',
                    },
                  },
                }}
                selected={location.pathname === item.path}
                onClick={handleDrawerClose}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                    color: '#ffffff',
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, ml: 8, pt: 3, mt: 8 }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/equipment" element={<Equipment />} />
        </Routes>
      </Box>

      {/* Диалоговое окно с кнопками выбора */}
      <ThemeProvider theme={dialogTheme}>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>Wählen Sie den Arbeitsplan aus</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
              {Object.keys(sheetLinks).map((label) => (
                <Button
                  key={label}
                  variant="contained"
                  onClick={() => handleSheetSelect(sheetLinks[label])}
                  fullWidth
                >
                  {label}
                </Button>
              ))}
            </Box>
          </DialogContent>
        </Dialog>
      </ThemeProvider>


      <SwipeableDrawer
        anchor="bottom"
        open={swipeableDrawerOpen}
        onClose={() => setSwipeableDrawerOpen(false)}
        onOpen={() => setSwipeableDrawerOpen(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: 1300 }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: 'text.secondary' }}>Google Sheets</Typography>
        </StyledBox>
        <StyledBox
          sx={{
            height: '95vh',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              height: '100%',
              transform: 'scale(1)', // Убираем увеличение
              transformOrigin: '0 0',
              width: '100%', // Обеспечиваем, что содержимое занимает всю ширину контейнера
            }}
          >
            {selectedSheetLink && (
              <iframe
                id="google-sheets-iframe"
                src={selectedSheetLink}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Google Sheets"
              ></iframe>
            )}
          </Box>
        </StyledBox>
      </SwipeableDrawer>
    </Box>
  );
}

const App = ({ isAuthenticated }) => {
  const [authState, setAuthState] = useState(isAuthenticated);

  useEffect(() => {
    setAuthState(isAuthenticated);
  }, [isAuthenticated]);

  const handleLogin = () => {
    setAuthState(true);
  };

  const handleLogout = () => {
    setAuthState(false);
  };

  if (!authState) {
    return <SignInSide onLogin={handleLogin} />;
  }

  return (
    <Router>
      <Main onLogout={handleLogout} />
    </Router>
  );
};

export default App;
